const { sendApi } = require('./base.api')

const createVariant = async (variantRequest = {}) => await sendApi('post', `/variants/create`, variantRequest)
const batchGetVariants = async (variantRequest = {}) => await sendApi('post', `/variants/batchGet`, variantRequest)
const getVariant = async (id = ``) => await sendApi('post', `/variants/${id}/get`)
const updateVariant = async (id = ``, variantRequest = {}) => await sendApi('post', `/variants/${id}/update`, variantRequest)
const deleteVariant = async (id = ``) => await sendApi('post', `/variants/${id}/delete`)

module.exports = {
	createVariant,
	batchGetVariants,
	getVariant,
	updateVariant,
	deleteVariant,
}
