import moment from 'moment'

export function getProductTotal(products) {
	return Math.round(products.reduce((sum, product) => sum + product.price * product.quantity, 0)*10)/10
}

export function getDiscountTotal(products, discounts, productTotal) {
	const discountTotal = discounts.reduce((sum, discount) => {
		switch (discount.discountType) {
			case 'ORDER_AMOUNT': {
				return Math.round((sum + discount.amount)*10)/10
			}
			case 'ORDER_PERCENTAGE': {
				return Math.round((sum + (productTotal * (discount.percentage / 100)))*10)/10
			}
			case 'BRAND_AMOUNT': {
				let total = 0
				const filteredProducts = [...products.filter(p => p.brandId === discount.compareId)]
				filteredProducts.forEach(p => (total += p.quantity * discount.amount))
				return Math.round((sum + total)*10)/10
			}
			case 'BRAND_PERCENTAGE': {
				let total = 0
				const filteredProducts = [...products.filter(p => p.brandId === discount.compareId)]
				filteredProducts.forEach(p => (total += p.price * p.quantity * (discount.percentage / 100)))
				return Math.round((sum + total)*10)/10
			}
			case 'CATEGORY_AMOUNT': {
				let total = 0
				const filteredProducts = [...products.filter(p => p.categoryId === discount.compareId)]
				filteredProducts.forEach(p => (total += p.quantity * discount.amount))
				return Math.round((sum + total)*10)/10
			}
			case 'CATEGORY_PERCENTAGE': {
				let total = 0
				const filteredProducts = [...products.filter(p => p.categoryId === discount.compareId)]
				filteredProducts.forEach(p => (total += p.price * p.quantity * (discount.percentage / 100)))
				return Math.round((sum + total)*10)/10
			}
			case 'PRODUCT_AMOUNT': {
				let total = 0
				const filteredProducts = [...products.filter(p => p.id === discount.compareId)]
				filteredProducts.forEach(p => (total += p.quantity * discount.amount))
				return Math.round((sum + total)*10)/10
			}
			case 'PRODUCT_PERCENTAGE': {
				let total = 0
				const filteredProducts = [...products.filter(p => p.id === discount.compareId)]
				filteredProducts.forEach(p => (total += p.price * p.quantity * (discount.percentage / 100)))
				return Math.round((sum + total)*10)/10
			}
		}
	}, 0)
	console.log(productTotal)
	if (discountTotal > productTotal) return productTotal
	console.log(discountTotal)
	return discountTotal
}

export function getServices(cart, services) {
	const result = []
	if (cart.shipment.type === 'SITE') return result
	services.forEach(service => {
		switch (service.serviceType) {
			case 'TOTAL_AMOUNT': {
				const total = cart.productTotal - cart.discountTotal
				if (total >= service.compareStart && total < service.compareEnd) result.push(service)
				break
			}
			case 'HOUR_PERCENTAGE': {
                if (!cart.shipment.date || !cart.shipment.time) break
				const now = moment()
				const shipmentDateTime = moment(`${cart.shipment.date} ${cart.shipment.time}`)
				const hours = Math.abs(Math.round(moment.duration(now.diff(shipmentDateTime)).asHours() * 10) / 10)
				if (hours >= service.compareStart && hours < service.compareEnd) result.push(service)
				break
			}
			case 'HOUR_ON_HOLD': {
                if (!cart.shipment.date || !cart.shipment.time) break
                const now = moment()
				const shipmentDateTime = moment(`${cart.shipment.date} ${cart.shipment.time}`)
				const hours = Math.abs(Math.round(moment.duration(now.diff(shipmentDateTime)).asHours() * 10) / 10)
				if (hours >= service.compareStart && hours < service.compareEnd) result.push(service)
				break
            }
			case 'SLOT_AMOUNT': {
                if (!cart.shipment.time) break
                const shipmentTime = moment(cart.shipment.time, "HH:mm")
                const startTime = moment(service.compareStart, "HH:mm")
                const endTime = moment(service.compareEnd, "HH:mm")
				if (shipmentTime.hour() <= 12) shipmentTime.add(1, 'days')
				if (startTime.hour() >= 12 && endTime.hour() <= 12) endTime.add(1, 'days')
                if (shipmentTime.isBetween(startTime, endTime)) result.push(service)
				break
            }
		}
	})
	return result
}

export function getServiceTotal(cart) {
	const total = cart.productTotal - cart.discountTotal
	return cart.services.reduce((sum, service) => {
		switch (service.serviceType) {
			case 'TOTAL_AMOUNT': {
				return Math.round((sum + service.amount) * 10)/10
			}
			case 'HOUR_PERCENTAGE': {
                return Math.round((sum + total * (service.percentage / 100)) * 10)/10
			}
			case 'HOUR_ON_HOLD': {
                return Math.round(sum * 10)/10
            }
			case 'SLOT_AMOUNT': {
                return Math.round((sum + service.amount) * 10)/10
            }
		}
	}, 0)
}