export function Customer() {
	return {
		id: null,
		firstName: '',
		lastName: '',
		areaCode: '',
		phoneNumber: '',
		emailAddress: '',
		gender: '',
		birthday: '',
		billingAddress: {
			firstName: '',
			lastName: '',
			areaCode: '',
			phoneNumber: '',
			addressLine: '',
		},
		accumulatedPoints: 0,
		pointBalance: 0,
		inviteCode: null,
		usedInviteCode: null,
		inviteCount: 0,
		invitePoints: 0,
		appNotification: true,
		emailNotification: true,
		whatsappNotification: true,
		smsNotification: true,
		status: 'ACTIVE',
		createdAt: Date.now(),
		updatedAt: Date.now(),
	}
}

export function CustomerAddress() {
	return {
		type: '',
		firstName: '',
		lastName: '',
		phoneNumber: '',
		emailAddress: '',
		country: '',
		state: '',
		city: '',
		district: '',
		addressLine1: '',
		addressLine2: '',
	}
}
