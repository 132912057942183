import { defineStore, storeToRefs } from 'pinia'
import { ref, computed } from 'vue'
import { useSharedStore } from './shared'
import { draftCart } from '@/models/cart'
import { saleApi } from 'gox-sdk'

export const useCartStore = defineStore('cartStore', () => {
	// state
	const currentProduct = ref()
	const currentDiscount = ref()
	const currentPayment = ref()
	const { bind, showLoading } = storeToRefs(useSharedStore())
	const { showAlert, unbindDoc } = useSharedStore()

	const currentCart = computed(() => bind.value.draftCart)
	const saleMethod = computed(() => bind.value.draftCart.method.type)
	function inCart(id) {
		if (!currentCart.value.id) return
		const product = currentCart.value.products.find(product => product.status === 'CLOSED' && product.id === id)
		if (!product) return
		const index = currentCart.value.products.indexOf(product)
		return { product, index }
	}
	function cartProductQuantity(id) {
		const { product } = inCart(id)
		// if (!currentCart.value.id) return 0
		// const product = currentCart.value.products.find(product =>
		// 	product.status === "CLOSED" && product.id === id
		// )
		if (product) return product.orderedQuantity
		return 0
	}

	// cart function
	function setCurrentCartMethod(saleMethod) {
		if (bind.value.draftCart.method) return
		bind.value.draftCart = {
			...bind.value.draftCart,
			method: saleMethod,
		}
		localStorage.setItem('saleMethod', JSON.stringify(saleMethod))
	}

	async function changeSaleMethod(saleMethod) {
		if (currentCart.value.id) return await saleApi.updateSaleMethod(currentCart.value.id, { method: saleMethod })
		bind.value.draftCart = {
			...bind.value.draftCart,
			method: saleMethod,
		}
		localStorage.setItem('saleMethod', JSON.stringify(saleMethod))
	}

	function setCurrentProduct(product) {
		currentProduct.value = {
			id: product.id,
			name: product.name,
			orderedQuantity: 1,
			variantId: '',
			extras: [],
			total: 0,
		}
	}

	function setCurrentVariant(variant) {
		currentProduct.value = {
			...currentProduct.value,
			variantId: variant.id,
			variantName: variant.name,
			// stationIds: variant.stationIds,
			sellingPrice: variant.sellingPrice ?? {
				DELIVERY: 0,
				ONSITE: 0,
				PICKUP: 0,
			},
		}
		// updateCurrentProductTotal()
	}

	function setCurrentExtraItem(extra, item) {
		const extraObj = {
			id: extra.id,
			name: extra.name,
			items: [],
		}
		const itemObj = {
			id: item.id,
			name: item.name,
			quantity: 1,
			price: item.price,
		}

		if (currentProduct.value.extras.length == 0) {
			extraObj.items.push(itemObj)
			currentProduct.value.extras.push(extraObj)
			updateCurrentProductTotal()
			return
		}
		const { extraIndex, itemIndex } = getCurrentExtraItem(extra, item)

		if (itemIndex > -1 || (itemIndex > -1 && extra.maxSelected == 1)) {
			currentProduct.value.extras[extraIndex].items.splice(itemIndex, 1)
			updateCurrentProductTotal()
			return
		}
		if (getCurrentExtraQty(extra) < extra.maxSelected) currentProduct.value.extras[extraIndex].items.push(itemObj)
		updateCurrentProductTotal()
		return
	}

	function verifyCurrentExtraItem(extra, item) {
		if (currentProduct.value.extras.length == 0) return false
		const { itemIndex } = getCurrentExtraItem(extra, item)
		if (itemIndex > -1) return true
	}

	function getCurrentExtraItem(extra, item) {
		let extraIndex = 0
		let itemIndex = 0
		extraIndex = currentProduct.value.extras.findIndex(e => e.id === extra.id)
		if (item) itemIndex = currentProduct.value.extras[extraIndex].items.findIndex(i => i.id === item.id)
		return { extraIndex, itemIndex }
	}

	function addCurrentItemQty(extra, item) {
		const { extraIndex, itemIndex } = getCurrentExtraItem(extra, item)
		const currentItem = currentProduct.value.extras[extraIndex].items[itemIndex]
		currentItem.quantity++
		updateCurrentProductTotal()
	}

	function minusCurrentItemQty(extra, item) {
		const { extraIndex, itemIndex } = getCurrentExtraItem(extra, item)
		const currentItem = currentProduct.value.extras[extraIndex].items[itemIndex]
		currentItem.quantity--
		updateCurrentProductTotal()
		if (currentItem.quantity == 0) return currentProduct.value.extras[extraIndex].items.splice(itemIndex, 1)
		if (currentItem.quantity < 0) return
	}

	function getCurrentItemQty(extra, item) {
		const { extraIndex, itemIndex } = getCurrentExtraItem(extra, item)
		const currentItem = currentProduct.value.extras[extraIndex].items[itemIndex]
		return currentItem.quantity
	}

	function getCurrentExtraQty(extra) {
		const { extraIndex } = getCurrentExtraItem(extra)
		let quantity = 0
		if (extraIndex > -1) currentProduct.value.extras[extraIndex].items.forEach(item => (quantity += item.quantity))
		return quantity
	}

	function updateCurrentProductTotal() {
		console.log('updateCurrentProductTotal')
		showLoading.value = true
		let total = 0
		let itemTotal = 0
		let extraTotal = 0
		let variantTotal = 0

		if (currentProduct.value.extras.length > 0) {
			currentProduct.value.extras.forEach(extra => {
				console.log('updateCurrentProductTotal2')
				if (extra.items.length <= 0) return (extra.total = 0)
				extra.items.forEach(item => {
					item.total = item.price[currentCart.value.method.type] * item.quantity
					itemTotal += item.total
				})
				extra.total = itemTotal
				itemTotal = 0
				extraTotal += extra.total
			})
		}
		variantTotal = currentProduct.value.price[currentCart.value.method.type]
		variantTotal += extraTotal
		total += variantTotal
		total = Math.round(currentProduct.value.orderedQuantity * total * 10) / 10
		showLoading.value = false
		return (currentProduct.value.total = total)
	}

	function addCurrentProductQty() {
		if (currentProduct.value.orderedQuantity == 99) return
		currentProduct.value.orderedQuantity++
		// updateCurrentProductTotal()
	}

	function minusCurrentProductQty() {
		if (currentProduct.value.orderedQuantity <= 1) return
		currentProduct.value.orderedQuantity--
		// updateCurrentProductTotal()
	}

	function addCartProduct() {
		currentCart.value.products.unshift(currentProduct.value)
		updateCartTotal()
	}
	function updateCartProduct(index, cartProduct) {
		const result = verifyCartDiscount({ index, cartProduct, cartDiscount: currentCart.value.discounts[0] })
		if (!result) return false
		currentCart.value.products.splice(index, 1, cartProduct)
		updateCartTotal()
		return true
	}
	function addCartProductQty(index, product) {
		currentProduct.value = product
		addCurrentProductQty()
		updateCartProduct(index, currentProduct.value)
	}
	function minusCartProductQty(index, cartProduct) {
		currentProduct.value = cartProduct
		minusCurrentProductQty()
		const result = verifyCartDiscount({ index, cartProduct, cartDiscount: currentCart.value.discounts[0] })
		if (!result) return addCartProductQty(index, cartProduct)
		return updateCartProduct(index, currentProduct.value)
	}
	function removeCartProduct(index) {
		currentCart.value.products.splice(index, 1)
		const result = verifyCartDiscount({ cartDiscount: currentCart.value.discounts[0] })
		if (!result) return removeCartDiscount(0)
		return updateCartTotal()
	}
	function removeCartDiscount(index) {
		currentCart.value.discounts.splice(index, 1)
		updateCartTotal()
	}
	function addCartDiscount(discount) {
		if (discount.offers[0].type === 'SALE_PERCENTAGE') {
			discount.total = Math.round(((currentCart.value.productTotal * discount.offers[0]['percentage']) / 100) * 10) / 10
			currentCart.value.discounts = [{ ...discount }]
		}

		if (discount.offers[0].type === 'SALE_TOTAL') {
			discount.total = discount.offers[0]['total']
			currentCart.value.discounts = [{ ...discount }]
		}
		updateCartTotal()
	}
	function verifyCartDiscount({ index = null, cartProduct, cartDiscount = null }) {
		if (!cartDiscount) return true
		const verifyCurrentCart = JSON.parse(JSON.stringify(currentCart.value))
		if (index) verifyCurrentCart.products.splice(index, 1, cartProduct)
		let productTotal = 0
		verifyCurrentCart.products.forEach(product => {
			productTotal += product.total
		})
		if (cartDiscount.conditions[0].type !== 'SALE_TOTAL' || cartDiscount.conditions[0].total > productTotal) {
			showAlert({ message: '商品總計不滿足優惠' })
			return false
		}
		if (cartDiscount.conditions[0].type === 'SALE_TOTAL' && cartDiscount.conditions[0].total <= productTotal) return true
	}

	function updateCartTotal() {
		currentCart.value.productTotal = 0
		currentCart.value.discountTotal = 0
		currentCart.value.products.forEach(product => (currentCart.value.productTotal += product.total))
		currentCart.value.discounts.forEach(discount => (currentCart.value.discountTotal += discount.total))
		currentCart.value.grandTotal = Math.round((currentCart.value.productTotal - currentCart.value.discountTotal) * 10) / 10
	}

	function setCurrentCart(currentSale) {
		currentCart.value = JSON.parse(JSON.stringify(currentSale))
	}

	function resetCart() {
		unbindDoc('draftCart')
		bind.value.draftCart = draftCart()
		// currentCart.value = {
		// 	method: JSON.parse(localStorage.getItem('saleMethod')),
		// 	products: [],
		// 	productTotal: 0,
		// 	discounts: [],
		// 	discountTotal: 0,
		// 	grandTotal: 0,
		// 	remark: '',
		// 	payments: [],
		// }
	}
	return {
		currentDiscount,
		currentProduct,
		currentCart,
		saleMethod,
		currentPayment,
		inCart,
		cartProductQuantity,
		setCurrentCartMethod,
		setCurrentProduct,
		setCurrentVariant,
		setCurrentExtraItem,
		verifyCurrentExtraItem,
		addCurrentProductQty,
		minusCurrentProductQty,
		addCurrentItemQty,
		minusCurrentItemQty,
		getCurrentItemQty,
		getCurrentExtraQty,
		getCurrentExtraItem,
		addCartProduct,
		updateCartProduct,
		addCartProductQty,
		minusCartProductQty,
		removeCartProduct,
		removeCartDiscount,
		addCartDiscount,
		resetCart,
		setCurrentCart,
		changeSaleMethod,
	}
})
