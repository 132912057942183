import { initializeApp } from 'firebase/app'
import { initializeAuth, getAuth, indexedDBLocalPersistence } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
import { Capacitor } from '@capacitor/core'

const firebaseConfig = {
	apiKey: 'AIzaSyDiqTV-aGL1iriiMZnLQs0TSeMqJtbYHUA',
	authDomain: 'gox-dev-0511.firebaseapp.com',
	projectId: 'gox-dev-0511',
	storageBucket: 'gox-dev-0511.appspot.com',
	messagingSenderId: '731294803957',
	appId: '1:731294803957:web:ece37079983f552e8f62bc',
}

const app = initializeApp(firebaseConfig)
if (Capacitor.isNativePlatform()) initializeAuth(app, { persistence: indexedDBLocalPersistence })
export const auth = getAuth(app)
export const db = getFirestore(app)
