<template>
	<div class="fixed -left-0 top-0 z-75 flex h-screen w-screen items-center justify-center p-3 backdrop-blur-md backdrop-contrast-75 backdrop-filter">
		<div class="relative mb-20 flex h-content max-h-96 w-full flex-col items-center justify-between rounded-lg bg-white p-5">
			<div class="relative w-full">
				<!-- <div class="absolute left-3" @click="$emit('close')" ><Icon class="h-5 w-5" icon="xmark" /></div> -->
				<!-- title -->
				<p class="text-center">{{ title }}</p>
				<!-- subtitle -->
				<p class="mt-2 text-center text-sm text-muted">{{ subTitle }}</p>
			</div>
			<slot />
			<div class="flex w-full gap-5">
				<Button variant="primary" @click="$emit('submit')">{{ submitLabel }}</Button>
				<Button variant="danger" @click="$emit('close')">{{ closeLabel }}</Button>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: ['title', 'subTitle', 'submitLabel', 'closeLabel'],
	emits: ['close', 'submit'],
}
</script>
