export function Cart() {
	return {
		items: [],
		remark: '',
	}
}

export function draftCart() {
	return {
		method: null,
		reservationTime: 0,
		products: [],
		productTotal: 0,
		discounts: [],
		discountTotal: 0,
		grandTotal: 0,
		payments: [],
		remark: '',
	}
}
