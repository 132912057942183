<template>
	<div class="fixed top-0 z-75 grid h-36 max-h-96 w-screen grid-cols-8 items-center justify-center gap-5 bg-red-400 font-bold text-white pt-safe" role="alert">
		<button class="col-span-1 grid w-full justify-start px-5 text-white" @click="$emit('close')">
			<Icon class="h-5 w-5" icon="xmark" />
		</button>
		<div class="col-span-7 grid">
			<slot />
		</div>
	</div>
</template>

<script>
export default {
	emits: ['close'],
}
</script>
