<template>
	<div class="fixed z-75 h-screen w-screen">
		<div class="mx-auto flex h-full w-full items-center justify-center">
			<div class="flex w-1/2 flex-col items-center justify-center space-y-2 rounded-xl bg-white bg-opacity-90 p-5 text-primary">
				<svg class="-ml-1 mr-3 h-5 w-5 animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
					<circle class="text-white" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
					<path class="" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
				</svg>
				<p>{{ title }}</p>
				<Button v-if="refresh" @click="$emit('onClick')">
					{{ refresh }}
				</Button>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: ['title', 'refresh'],
	emits: ['onClick'],
}
</script>
