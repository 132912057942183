const { sendApi } = require('./base.api')

const createRole = async (roleRequest = {}) => await sendApi('post', `/roles/create`, roleRequest)
const batchGetRoles = async (roleRequest = {}) => await sendApi('post', `/roles/batchGet`, roleRequest)
const getRole = async (id = ``) => await sendApi('post', `/roles/${id}/get`)
const updateRole = async (id = ``, roleRequest = {}) => await sendApi('post', `/roles/${id}/update`, roleRequest)
const deleteRole = async (id = ``) => await sendApi('post', `/roles/${id}/delete`)

module.exports = {
	createRole,
	batchGetRoles,
	getRole,
	updateRole,
	deleteRole,
}
