const { sendApi } = require('./base.api')

const createNotice = async (noticeRequest = {}) => await sendApi('post', `/notices/create`, noticeRequest)
const batchGetNotices = async (noticeRequest = {}) => await sendApi('post', `/notices/batchGet`, noticeRequest)
const getNotice = async (id = ``) => await sendApi('post', `/notices/${id}/get`)
const updateNotice = async (id = ``, noticeRequest = {}) => await sendApi('post', `/notices/${id}/update`, noticeRequest)
const deleteNotice = async (id = ``) => await sendApi('post', `/notices/${id}/delete`)

module.exports = {
	createNotice,
	batchGetNotices,
	getNotice,
	updateNotice,
	deleteNotice,
}
