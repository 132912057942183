<template>
	<div class="h-15 fixed top-0 z-50 grid w-screen grid-cols-3 items-center px-5 pb-4 text-base" :class="classes">
		<div class="inline-flex justify-start gap-2" @click="$emit('clickLeft')">
			<slot name="start" />
		</div>
		<div class="inline-flex justify-center whitespace-nowrap">
			<slot />
		</div>
		<div class="inline-flex justify-end gap-2" @click="$emit('clickRight')">
			<slot name="end" />
		</div>
		<div class="flex w-screen -ml-5 px-5 items-center space-x-3">
			<!-- <div class="w-full "> -->
			<slot name="search" />
			<!-- </div> -->

		</div>

	</div>
</template>

<script setup>
import { storeToRefs } from 'pinia'
import { useSharedStore } from '@/store/shared'
import { reactive, computed } from 'vue'

const { config } = storeToRefs(useSharedStore())

const props = defineProps({
	variant: {
		default: 'primary',
	},
	platform: {
		default: 'web',
	},
})

const classNameMap = reactive({
	variant: {
		primary: 'bg-primary text-white',
		white: 'bg-white text-black',
		transparent: 'bg-transparent text-black',
	},
	platform: {
		ios: 'pt-safe',
		web: 'pt-4',
		android: 'pt-4',
	},
})

const classes = computed(() => {
	const result = []
	if (props.variant) result.push(classNameMap.variant[props.variant])
	if (config.value.platform) result.push(classNameMap.platform[config.value.platform])
	return result.join(' ')
})
</script>
