<template>
    <div class="grid grid-cols-8 text-base">
        <label class="col-span-3 ">{{ title }}</label>
        <div class="col-span-5 text-right overflow-ellipsis overflow-hidden ">
            <slot />
        </div>
    </div>

</template>

<script>
export default {
    props: ['title']
}
</script>
