const { sendApi } = require('./base.api')

const createDepartment = async (departmentRequest = {}) => await sendApi('post', `/departments/create`, departmentRequest)
const batchGetDepartments = async (departmentRequest = {}) => await sendApi('post', `/departments/batchGet`, departmentRequest)
const getDepartment = async (id = ``) => await sendApi('post', `/departments/${id}/get`)
const updateDepartment = async (id = ``, departmentRequest = {}) => await sendApi('post', `/departments/${id}/update`, departmentRequest)
const deleteDepartment = async (id = ``) => await sendApi('post', `/departments/${id}/delete`)

module.exports = {
	createDepartment,
	batchGetDepartments,
	getDepartment,
	updateDepartment,
	deleteDepartment,
}
