const { sendApi } = require('./base.api')

const createSchedule = async (scheduleRequest = {}) => await sendApi('post', `/schedules/create`, scheduleRequest)
const batchGetSchedules = async (scheduleRequest = {}) => await sendApi('post', `/schedules/batchGet`, scheduleRequest)
const getSchedule = async (id = ``) => await sendApi('post', `/schedules/${id}/get`)
const updateSchedule = async (id = ``, scheduleRequest = {}) => await sendApi('post', `/schedules/${id}/update`, scheduleRequest)
const deleteSchedule = async (id = ``) => await sendApi('post', `/schedules/${id}/delete`)

module.exports = {
	createSchedule,
	batchGetSchedules,
	getSchedule,
	updateSchedule,
	deleteSchedule,
}
