import { defineStore } from 'pinia'
import { computed } from 'vue'
import { onSnapshot, doc, getDoc } from 'firebase/firestore'
import { db } from '../firebase'
import { draftCart } from '@/models/cart'
// import { useFirebaseStore } from './firebase'
import { ref } from 'vue'

export const useSharedStore = defineStore(
	'sharedStore',
	() => {
		// state
		// const { db } = useFirebaseStore()
		const keyword = ref('')
		const chatMessage = ref('')
		const currentLang = ref('TC')
		const unsubscribes = ref({})
		const paypalData = ref(null)
		const bind = ref({
			currentCustomer: null,
			draftCart: draftCart(),
			currentShop: null,
			catalog: [],
			menus: [],
			categories: [],
			products: [],
			variants: [],
			extras: [],
			items: [],
			discounts: [],
			vouchers: [],
			sales: [],
			preOrders: [],
			currentSale: null,
			staffs: [],
			currentStaff: null,
			day: [],
			shift: [],
			drawer: [],
		})
		const config = ref({
			platform: 'web',
			merchantConfig: 1,
			shopConfig: null,
		})
		const showLoading = ref(false)
		const showError = ref(false)
		const errorMessage = ref('')
		const showResetCart = ref(false)
		const showSaveOrder = ref(false)
		const showPreOrder = ref(false)
		const showCashDrawer = ref(false)
		const showDay = ref(false)
		const showShift = ref(false)
		const showDrawer = ref(false)
		const staffLogout = ref(false)

		// global
		function setLang(value) {
			currentLang.value = value
			localStorage.setItem('footmark-lang', value)
		}

		function bindCollection({ key, ref }) {
			console.time(`bindCollection ${key}`)
			showLoading.value = true
			unsubscribes.value[key] = onSnapshot(ref, querySnap => {
				bind.value[key] = []
				console.timeEnd(`bindCollection ${key}`)
				querySnap.forEach(doc => bind.value[key].push(doc.data()))
				showLoading.value = false
			})
		}
		function unbindCollection(key) {
			if (bind.value[key] == null) return
			unsubscribes.value[key]()
			bind.value[key] = []
		}
		function bindDoc(key, collection, val) {
			// console.error(`[ALERT] bind`, key)
			console.time(`bindDoc ${key}`)
			showLoading.value = true
			unsubscribes.value[key] = onSnapshot(doc(db, collection, val), doc => {
				bind.value[key] = {}
				// console.error(`[ALERT] onSnapshot`, doc.data())
				console.timeEnd(`bindDoc ${key}`)
				bind.value[key] = doc.data()
				showLoading.value = false
			})
		}
		function unbindDoc(key) {
			if (bind.value[key] == null) return
			unsubscribes.value[key]()
			bind.value[key] = null
		}
		async function verifyDoc(key, collection, val) {
			const docRef = doc(db, collection, val)
			const docSnap = await getDoc(docRef)

			if (docSnap.exists()) {
				bindDoc(key, collection, val)
				return true
			}
			return false
		}
		async function getJsonData(key, url) {
			const axios = require('axios').default
			const response = await axios({
				method: 'get',
				url,
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json; charset=utf-8',
				},
			})
			config.value[key] = response.data
			return response.data
		}
		function showAlert({ message }) {
			showError.value = true
			errorMessage.value = message
		}

		const currentMerchant = computed(() => bind.value.currentMerchant)
		const sales = computed(() => bind.value.sales)

		return {
			// state
			chatMessage,
			keyword,
			currentLang,
			unsubscribes,
			paypalData,
			bind,
			sales,
			config,
			showLoading,
			showError,
			errorMessage,
			showResetCart,
			showSaveOrder,
			showPreOrder,
			showCashDrawer,
			showDay,
			showShift,
			showDrawer,
			currentMerchant,
			staffLogout,
			// global
			bindCollection,
			unbindCollection,
			bindDoc,
			unbindDoc,
			getJsonData,
			showAlert,
			verifyDoc,
			setLang,
		}
	},
	{
		persist: {
			storage: sessionStorage,
			paths: ['bind.currentShop', 'bind.menus', 'bind.categories', 'bind.products', 'bind.variants'],
		},
	}
)
