import { createStore } from 'vuex'
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, sendPasswordResetEmail, signOut, PhoneAuthProvider, signInWithCredential } from 'firebase/auth'
import { collection, doc, query, where, orderBy, setDoc, getDocs, getDoc, updateDoc, deleteDoc, runTransaction, onSnapshot } from 'firebase/firestore'
import { auth, db } from '@/firebase'
import { getProductTotal, getDiscountTotal, getServices, getServiceTotal } from '@/utils'
import { SaleOrder } from '@/models/saleOrder'
import { Chat, Message } from '@/models/chat'
import { Customer } from '@/models/customer'
import moment from 'moment'

export default createStore({
	state: {
		lang: null,
		currentProduct: null,
		customer: null,
		addresses: null,
		wishlist: null,
		discounts: null,
		catalog: null,
		cart: null,
		orders: null,
		messages: null,
		messagesUnsub: null,
		loadingState: false,
		refreshState: false,
		config: {},
	},
	mutations: {
		setLang(state, value) {
			state.lang = value
		},
		setCustomer(state, { customer }) {
			state.customer = customer
		},
		setAddresses(state, { addresses }) {
			state.addresses = addresses
		},
		setWishlist(state, { wishlist }) {
			state.wishlist = wishlist
		},
		setDiscounts(state, { discounts }) {
			state.discounts = discounts
		},
		setCatalog(state, { catalog }) {
			state.catalog = catalog
		},
		setCart(state, { cart }) {
			state.cart = cart
		},
		setOrders(state, { orders }) {
			state.orders = orders
		},
		setMessages(state, { messages }) {
			state.messages = messages
		},
		setMessagesUnsub(state, { messagesUnsub }) {
			state.messagesUnsub = messagesUnsub
		},
		setLoadingState(state, { loadingState, refreshState }) {
			state.loadingState = loadingState
			state.refreshState = refreshState
		},
		setJsonData(state, { key, response }) {
			console.log('setJsonData')
			console.log(key)
			console.log(response)
			state.config[key] = response
		},
	},
	actions: {
		async getJsonData({ commit }, { key, url }) {
			// console.log('actiongetJsonData',key,url)
			const axios = require('axios').default
			const response = await axios(url)
			// console.log(response.data)
			commit('setJsonData', { key, response: response.data })
		},

		// 會員註冊
		async registerWithPhone({ dispatch }, { areaCode, phoneNumber, vid, vcode }) {
			const credential = PhoneAuthProvider.credential(vid, vcode)
			const userCredential = await signInWithCredential(auth, credential)
			const user = userCredential.user
			await dispatch('createCustomer', { id: user.uid, areaCode: areaCode, phoneNumber: phoneNumber })
		},
		async registerWithEmail({ dispatch }, { emailAddress, password }) {
			const userCredential = await createUserWithEmailAndPassword(auth, emailAddress, password)
			const user = userCredential.user
			await dispatch('createCustomer', { id: user.uid, emailAddress: user.email })
		},
		async signInWithPhone({ dispatch }, { vid, vcode }) {
			const credential = PhoneAuthProvider.credential(vid, vcode)
			await signInWithCredential(auth, credential)
			await dispatch('getCustomer')
		},
		async signInWithEmail({ dispatch }, { emailAddress, password }) {
			await signInWithEmailAndPassword(auth, emailAddress, password)
			await dispatch('getCustomer')
		},
		async forgotPassword(store, { emailAddress }) {
			const customerSnap = await getDocs(query(collection(db, 'customers'), where('emailAddress', '==', emailAddress)))
			if (customerSnap.docs.length === 0) throw { code: 'auth/customer-not-found', message: 'Error (auth/customer-not-found).' }
			const customer = customerSnap.docs[0].data()
			if (customer.status !== 'ACTIVE') throw { code: 'auth/customer-not-active', message: 'Error (auth/customer-not-active).' }
			await sendPasswordResetEmail(auth, emailAddress)
		},
		async signOut({ commit, dispatch }) {
			await signOut(auth)
			await dispatch('unbindMessages')
			commit('setCustomer', { customer: null })
		},
		// 會員資料
		async createCustomer({ dispatch }, { id, emailAddress = '', areaCode = '', phoneNumber = '' }) {
			const customerSnap = await getDoc(doc(db, 'customers', id))
			if (customerSnap.exists()) {
				await signOut(auth)
				throw { code: 'auth/customer-already-exists', message: 'Error (auth/customer-already-exists).' }
			}
			const customer = { ...Customer(), id: id, emailAddress: emailAddress, areaCode: areaCode, phoneNumber: phoneNumber, inviteCode: id.substr(id.length - 8) }
			await setDoc(doc(db, 'customers', customer.id), customer)
			await dispatch('getCustomer')
		},
		async getCustomer({ commit }) {
			const user = auth.currentUser
			if (!user) throw { code: 'auth/user-not-found', message: 'Error (auth/user-not-found).' }
			const customerSnap = await getDoc(doc(db, 'customers', user.uid))
			if (!customerSnap.exists()) throw { code: 'auth/customer-not-found', message: 'Error (auth/customer-not-found).' }
			const customer = customerSnap.data()
			if (customer.status !== 'ACTIVE') throw { code: 'auth/customer-not-active', message: 'Error (auth/customer-not-active).' }
			commit('setCustomer', { customer: customer })
		},
		async updateCustomer({ dispatch }, { customer }) {
			await updateDoc(doc(db, 'customers', customer.id), customer)
			await dispatch('getCustomer')
		},
		// 會員地址
		async createAddress({ state, dispatch }, { address }) {
			address.id = doc(collection(db, 'customers', state.customer.id, 'addresses')).id
			await setDoc(doc(db, 'customers', state.customer.id, 'addresses', address.id), address)
			await dispatch('getAddresses')
		},
		async getAddresses({ state, commit }) {
			const addresses = []
			const addressSnap = await getDocs(collection(db, 'customers', state.customer.id, 'addresses'))
			addressSnap.forEach(doc => addresses.push(doc.data()))
			commit('setAddresses', { addresses: addresses })
		},
		async getAddress({ state }, { id }) {
			const addressSnap = await getDoc(doc(db, 'customers', state.customer.id, 'addresses', id))
			if (!addressSnap.exists()) throw { code: 'customer/address-not-found', message: 'Error (customer/address-not-found).' }
			const address = addressSnap.data()
			return address
		},
		async updateAddress({ state, dispatch }, { address }) {
			await updateDoc(doc(db, 'customers', state.customer.id, 'addresses', address.id), address)
			await dispatch('getAddresses')
		},
		async deleteAddress({ state, dispatch }, { address }) {
			await deleteDoc(doc(db, 'customers', state.customer.id, 'addresses', address.id))
			await dispatch('getAddresses')
		},
		// 喜好清單
		async createWishlist({ state, dispatch }, { product }) {
			await setDoc(doc(db, 'customers', state.customer.id, 'wishlist', product.id), product)
			await dispatch('getWishlist')
		},
		async getWishlist({ state, commit }) {
			const wishlist = []
			const wishlistSnap = await getDocs(collection(db, 'customers', state.customer.id, 'wishlist'))
			wishlistSnap.forEach(doc => wishlist.push(doc.data()))
			commit('setWishlist', { wishlist: wishlist })
		},
		async deleteWishlist({ state, dispatch }, { product }) {
			await deleteDoc(doc(db, 'customers', state.customer.id, 'wishlist', product.id), product)
			await dispatch('getWishlist')
		},
		// 會員折扣
		async createDiscount({ state, dispatch }, { code }) {
			const customerDiscountSnap = await getDocs(query(collection(db, 'customers', state.customer.id, 'discounts'), where('code', '==', code)))
			if (customerDiscountSnap.docs.length > 0) throw { code: 'customer/discount-already-added', message: 'Error (customer/discount-already-added).' }
			const discountSnap = await getDocs(query(collection(db, 'catalog'), where('type', '==', 'DISCOUNT'), where('code', '==', code)))
			if (discountSnap.docs.length === 0) throw { code: 'catalog/discount-not-found', message: 'Error (catalog/discount-not-found).' }
			const discount = discountSnap.docs[0].data()
			if (discount.status !== 'ACTIVE') throw { code: 'catalog/discount-not-active', message: 'Error (catalog/discount-not-active).' }
			await setDoc(doc(db, 'customers', state.customer.id, 'discounts', discount.id), discount)
			await dispatch('getDiscounts')
		},
		async getDiscounts({ state, commit }) {
			const discounts = []
			const discountSnap = await getDocs(collection(db, 'customers', state.customer.id, 'discounts'))
			discountSnap.forEach(doc => discounts.push(doc.data()))
			commit('setDiscounts', { discounts: discounts })
		},
		async getDiscount({ state }, { id }) {
			const discountSnap = await getDoc(doc(db, 'customers', state.customer.id, 'discounts', id))
			if (!discountSnap.exists()) throw { code: 'customer/discount-not-found', message: 'Error (customer/discount-not-found).' }
			const discount = discountSnap.data()
			return discount
		},
		// 商品資料
		async getCatalog({ commit }) {
			const catalog = []
			const catalogSnap = await getDocs(collection(db, 'catalog'), where('type', 'in', ['CATEGORY', 'PRODUCT', 'SERVICE', 'QUESTION']), where('status', '==', 'ACTIVE'))
			catalogSnap.forEach(doc => catalog.push(doc.data()))
			commit('setCatalog', { catalog: catalog })
		},
		// 購物車
		async createCart({ state, dispatch }) {
			const cart = { ...SaleOrder(), id: doc(collection(db, 'customers', state.customer.id, 'addresses')).id, customer: state.customer }
			await setDoc(doc(db, 'saleOrders', cart.id), cart)
			await dispatch('getCart')
		},
		async getCart({ state, commit, dispatch }) {
			const cartSnap = await getDocs(query(collection(db, 'saleOrders'), where('customer.id', '==', state.customer.id), where('status', '==', 'DRAFT')))
			if (cartSnap.docs.length === 0) return dispatch('createCart')
			const cart = cartSnap.docs[0].data()
			commit('setCart', { cart: cart })
		},
		async setCartShipment({ state, dispatch, getters }, { shipment }) {
			await runTransaction(db, async transaction => {
				const cartSnap = await transaction.get(doc(db, 'saleOrders', state.cart.id))
				const cart = cartSnap.data()
				cart.shipment = shipment
				cart.productTotal = getProductTotal(cart.products)
				cart.discountTotal = getDiscountTotal(cart.products, cart.discounts, cart.productTotal)
				cart.services = getServices(cart, getters.services)
				cart.serviceTotal = getServiceTotal(cart)
				cart.grandTotal = cart.productTotal - cart.discountTotal + cart.serviceTotal - cart.usedPoints
				cart.earnedPoints = cart.grandTotal - cart.serviceTotal >= 0 ? Math.round(((cart.grandTotal - cart.serviceTotal) / 50) * 10) / 10 : 0
				transaction.update(doc(db, 'saleOrders', state.cart.id), cart)
			})
			await dispatch('getCart')
		},
		async addProductToCart({ state, dispatch, getters }, { product, quantity }) {
			await runTransaction(db, async transaction => {
				const cartSnap = await transaction.get(doc(db, 'saleOrders', state.cart.id))
				const cart = cartSnap.data()
				const index = cart.products.findIndex(p => p.id === product.id)
				if (index !== -1) cart.products[index] = { ...product, quantity, packedQuantity: 0 }
				else cart.products = [...cart.products, { ...product, quantity, packedQuantity: 0 }]
				cart.productTotal = getProductTotal(cart.products)
				cart.discountTotal = getDiscountTotal(cart.products, cart.discounts, cart.productTotal)
				cart.services = getServices(cart, getters.services)
				cart.serviceTotal = getServiceTotal(cart)
				cart.grandTotal = cart.productTotal - cart.discountTotal + cart.serviceTotal - cart.usedPoints
				cart.earnedPoints = cart.grandTotal - cart.serviceTotal >= 0 ? Math.round(((cart.grandTotal - cart.serviceTotal) / 50) * 10) / 10 : 0
				transaction.update(doc(db, 'saleOrders', state.cart.id), cart)
			})
			await dispatch('getCart')
		},
		async removeProductFromCart({ state, dispatch, getters }, { product }) {
			await runTransaction(db, async transaction => {
				const cartSnap = await transaction.get(doc(db, 'saleOrders', state.cart.id))
				const cart = cartSnap.data()
				cart.products = cart.products.filter(p => p.id !== product.id)
				cart.productTotal = getProductTotal(cart.products)
				cart.discountTotal = getDiscountTotal(cart.products, cart.discounts, cart.productTotal)
				cart.services = getServices(cart, getters.services)
				cart.serviceTotal = getServiceTotal(cart)
				cart.grandTotal = cart.productTotal - cart.discountTotal + cart.serviceTotal - cart.usedPoints
				cart.earnedPoints = cart.grandTotal - cart.serviceTotal >= 0 ? Math.round(((cart.grandTotal - cart.serviceTotal) / 50) * 10) / 10 : 0
				transaction.update(doc(db, 'saleOrders', state.cart.id), cart)
			})
			await dispatch('getCart')
		},
		async setProductQuantityInCart({ state, dispatch, getters }, { product, quantity }) {
			await runTransaction(db, async transaction => {
				const cartSnap = await transaction.get(doc(db, 'saleOrders', state.cart.id))
				const cart = cartSnap.data()
				const index = cart.products.findIndex(p => p.id === product.id)
				if (index !== -1) cart.products[index].quantity = quantity
				cart.productTotal = getProductTotal(cart.products)
				cart.discountTotal = getDiscountTotal(cart.products, cart.discounts, cart.productTotal)
				cart.services = getServices(cart, getters.services)
				cart.serviceTotal = getServiceTotal(cart)
				cart.grandTotal = cart.productTotal - cart.discountTotal + cart.serviceTotal - cart.usedPoints
				cart.earnedPoints = cart.grandTotal - cart.serviceTotal >= 0 ? Math.round(((cart.grandTotal - cart.serviceTotal) / 50) * 10) / 10 : 0
				transaction.update(doc(db, 'saleOrders', state.cart.id), cart)
			})
			await dispatch('getCart')
		},
		async addDiscountToCart({ state, dispatch, getters }, { discount, quantity }) {
			await runTransaction(db, async transaction => {
				const cartSnap = await transaction.get(doc(db, 'saleOrders', state.cart.id))
				const cart = cartSnap.data()
				cart.discounts[0] = { ...discount, quantity }
				cart.productTotal = getProductTotal(cart.products)
				cart.discountTotal = getDiscountTotal(cart.products, cart.discounts, cart.productTotal)
				cart.services = getServices(cart, getters.services)
				cart.serviceTotal = getServiceTotal(cart)
				cart.grandTotal = cart.productTotal - cart.discountTotal + cart.serviceTotal - cart.usedPoints
				cart.earnedPoints = cart.grandTotal - cart.serviceTotal >= 0 ? Math.round(((cart.grandTotal - cart.serviceTotal) / 50) * 10) / 10 : 0
				transaction.update(doc(db, 'saleOrders', state.cart.id), cart)
			})
			await dispatch('getCart')
		},
		async removeDiscountFromCart({ state, dispatch, getters }) {
			await runTransaction(db, async transaction => {
				const cartSnap = await transaction.get(doc(db, 'saleOrders', state.cart.id))
				const cart = cartSnap.data()
				cart.discounts = []
				cart.productTotal = getProductTotal(cart.products)
				cart.discountTotal = getDiscountTotal(cart.products, cart.discounts, cart.productTotal)
				cart.services = getServices(cart, getters.services)
				cart.serviceTotal = getServiceTotal(cart)
				cart.grandTotal = cart.productTotal - cart.discountTotal + cart.serviceTotal - cart.usedPoints
				cart.earnedPoints = cart.grandTotal - cart.serviceTotal >= 0 ? Math.round(((cart.grandTotal - cart.serviceTotal) / 50) * 10) / 10 : 0
				transaction.update(doc(db, 'saleOrders', state.cart.id), cart)
			})
			await dispatch('getCart')
		},
		async setUsedPointsInCart({ state, dispatch, getters }, { usedPoints }) {
			await runTransaction(db, async transaction => {
				const cartSnap = await transaction.get(doc(db, 'saleOrders', state.cart.id))
				const cart = cartSnap.data()
				cart.usedPoints = usedPoints
				cart.productTotal = getProductTotal(cart.products)
				cart.discountTotal = getDiscountTotal(cart.products, cart.discounts, cart.productTotal)
				cart.services = getServices(cart, getters.services)
				cart.serviceTotal = getServiceTotal(cart)
				cart.grandTotal = cart.productTotal - cart.discountTotal + cart.serviceTotal - cart.usedPoints
				cart.earnedPoints = cart.grandTotal - cart.serviceTotal >= 0 ? Math.round(((cart.grandTotal - cart.serviceTotal) / 50) * 10) / 10 : 0
				transaction.update(doc(db, 'saleOrders', state.cart.id), cart)
			})
			await dispatch('getCart')
		},
		async setCartRemark({ state, dispatch }, { remark }) {
			await runTransaction(db, async transaction => {
				const cartSnap = await transaction.get(doc(db, 'saleOrders', state.cart.id))
				const cart = cartSnap.data()
				cart.remark = remark
				transaction.update(doc(db, 'saleOrders', state.cart.id), cart)
			})
			await dispatch('getCart')
		},
		async checkoutCart({ state, dispatch, getters }) {
			await runTransaction(db, async transaction => {
				const cartSnap = await transaction.get(doc(db, 'saleOrders', state.cart.id))
				const cart = cartSnap.data()
				const customerSnap = await transaction.get(doc(db, 'customers', state.customer.id))
				const customer = customerSnap.data()
				if (customer.pointBalance < cart.usedPoints) throw { code: 'cart/invalid-used-points', message: 'Error (cart/invalid-used-points)' }
				const counterSnap = await transaction.get(doc(db, 'counters', 'sale-order'))
				const counter = counterSnap.data()
				if (counter.date !== moment().format('YYYY-MM-DD')) {
					counter.date = moment().format('YYYY-MM-DD')
					counter.count = 0
				}
				if (counter.count < 10) cart.referenceId = 'S-' + moment().format('YYMMDD') + '-000' + (counter.count + 1)
				else if (counter.count < 100) cart.referenceId = 'S-' + moment().format('YYMMDD') + '-00' + (counter.count + 1)
				else if (counter.count < 1000) cart.referenceId = 'S-' + moment().format('YYMMDD') + '-0' + (counter.count + 1)
				else cart.referenceId = 'S-' + moment().format('YYMMDD') + '-' + (counter.count + 1)
				let onRequestFlag = false
				for (let p of cart.products) {
					const productSnap = await transaction.get(doc(db, 'catalog', p.id))
					const product = productSnap.data()
					if (product.isOnRequest) onRequestFlag = true
					if (product.isSoldOut) throw { code: 'cart/product-sold-out', message: 'Error (cart/product-sold-out)' }
					if (product.status !== 'ACTIVE') throw { code: 'cart/product-not-active', message: 'Error (cart/product-not-active)' }
					if (p.price !== product.price) throw { code: 'cart/product-invalid-price', message: 'Error (cart/product-invalid-price)' }
				}
				for (let d of cart.discounts) {
					const discountSnap = await transaction.get(doc(db, 'customers', cart.customer.id, 'discounts', d.id))
					const discount = discountSnap.data()
					if (discount.status !== 'ACTIVE') throw { code: 'cart/discount-used', message: 'Error (cart/discount-used)' }
					transaction.update(doc(db, 'customers', cart.customer.id, 'discounts', d.id), { status: 'INACTIVE' })
				}
				cart.productTotal = getProductTotal(cart.products)
				cart.discountTotal = getDiscountTotal(cart.products, cart.discounts, cart.productTotal)
				cart.services = getServices(cart, getters.services)
				cart.serviceTotal = getServiceTotal(cart)
				cart.grandTotal = cart.productTotal - cart.discountTotal + cart.serviceTotal
				cart.earnedPoints = cart.grandTotal - cart.serviceTotal >= 0 ? Math.round(((cart.grandTotal - cart.serviceTotal) / 50) * 10) / 10 : 0
				cart.status = onRequestFlag === true ? 'ON_HOLD' : 'PENDING_PAYMENT'
				transaction.update(doc(db, 'saleOrders', state.cart.id), cart)
				counter.count += 1
				transaction.update(doc(db, 'counters', 'sale-order'), counter)
				//pointBalance 修正
				transaction.update(doc(db, 'customers', state.customer.id), { pointBalance: Math.round((customer.pointBalance - cart.usedPoints) * 10) / 10 })
			})
			await dispatch('getCart')
		},
		// 會員訂單
		async getOrders({ state, commit }) {
			const orders = []
			const orderSnap = await getDocs(query(collection(db, 'saleOrders'), where('customer.id', '==', state.customer.id), where('status', '!=', 'DRAFT')))
			orderSnap.forEach(doc => orders.push(doc.data()))
			commit('setOrders', { orders: orders })
		},
		async getOrder({ state }, { id }) {
			const orderSnap = await getDoc(doc(db, 'saleOrders', id))
			if (!orderSnap.exists()) throw { code: 'sale/order-not-found', message: 'Error (sale/order-not-found).' }
			const order = orderSnap.data()
			if (order.customer.id !== state.customer.id) throw { code: 'sale/invalid-customer', message: 'Error (sale/invalid-customer).' }
			if (order.status === 'DRAFT') throw { code: 'sale/draft-order', message: 'Error (sale/draft-order).' }
			return order
		},
		// 聯天室
		async createChat({ state, dispatch }) {
			const chat = { ...Chat(), id: state.customer.id }
			await setDoc(doc(db, 'chats', chat.id), chat)
			await dispatch('bindMessages')
		},
		async bindMessages({ state, commit, dispatch }) {
			const chatSnap = await getDoc(doc(db, 'chats', state.customer.id))
			if (!chatSnap.exists()) return dispatch('createChat')
			const messagesUnsub = onSnapshot(query(collection(db, 'chats', state.customer.id, 'messages'), orderBy('createdAt')), messageSnap => {
				const messages = []
				messageSnap.forEach(doc => messages.push(doc.data()))
				commit('setMessages', { messages: messages })
			})
			commit('setMessagesUnsub', { messagesUnsub: messagesUnsub })
		},
		async unbindMessages({ state, commit }) {
			if (state.messages) state.messagesUnsub()
			commit('setMessagesUnsub', { messagesUnsub: null })
		},
		async createMessage({ state }, { content }) {
			const message = { ...Message(), id: doc(collection(db, 'chats', state.customer.id, 'messages')).id, content: content }
			await setDoc(doc(db, 'chats', state.customer.id, 'messages', message.id), message)
			await runTransaction(db, async transaction => {
				const chatSnap = await transaction.get(doc(db, 'chats', state.customer.id))
				const chat = chatSnap.data()
				if (chat.lastMessage && chat.lastMessage.createdAt > message.createdAt) return
				transaction.update(doc(db, 'chats', state.customer.id), { lastMessage: message })
			})
		},
		async redeemInviteCode({ state }, { code }) {
			const id = state.customer.id
			if (code === id.substr(id.length - 8)) throw { code: 'customer/invalid-invite-code', message: 'Error (customer/invalid-invite-code).' }
			await runTransaction(db, async transaction => {
				const toCustomerSnap = await getDocs(query(collection(db, 'customers'), where('inviteCode', '==', code)))
				if (toCustomerSnap.docs.length === 0) throw { code: 'auth/customer-not-found', message: 'Error (auth/customer-not-found).' }
				const toCustomer = toCustomerSnap.docs[0].data()
				const toInviteCount = toCustomer.inviteCount + 1
				const toInvitePoints = toCustomer.invitePoints + 10
				const toAccumulatedPoints = toCustomer.accumulatedPoints + 10
				const toPointBalance = toCustomer.pointBalance + 10
				transaction.update(doc(db, 'customers', toCustomer.id), { inviteCount: toInviteCount, invitePoints: toInvitePoints, accumulatedPoints: toAccumulatedPoints, pointBalance: toPointBalance })
				const customerSnap = await getDoc(doc(db, 'customers', state.customer.id))
				const customer = customerSnap.data()
				if (customer.usedInviteCode) throw { code: 'customer/invite-code-redeemed', message: 'Error (customer/invite-code-redeemed).' }
				const usedInviteCode = code
				const accumulatedPoints = toCustomer.accumulatedPoints + 10
				const pointBalance = toCustomer.pointBalance + 10
				transaction.update(doc(db, 'customers', customer.id), { usedInviteCode: usedInviteCode, accumulatedPoints: accumulatedPoints, pointBalance: pointBalance })
			})
		},
	},
	getters: {
		merchants: state => {
			console.log(state.config)
			return state.config.merchants
		},
		categories: state => menuType => {
			const categories = [...state.catalog.filter(i => i.type === 'CATEGORY' && i.menuType === menuType)]
			categories.forEach(c => (c.products = [...state.catalog.filter(i => i.type === 'PRODUCT' && i.categoryId === c.id)]))
			return categories
		},
		category: state => id => {
			const category = { ...state.catalog.find(i => i.id === id && i.type === 'CATEGORY') }
			if (!category.id) return null
			category.products = [...state.catalog.filter(i => i.type === 'PRODUCT' && i.categoryId === category.id)]
			return category
		},
		product: state => id => {
			const product = { ...state.catalog.find(i => i.id === id && i.type === 'PRODUCT') }
			return product
		},
		services: state => {
			const services = [...state.catalog.filter(i => i.type === 'SERVICE')]
			return services
		},
		questions: state => {
			const questions = [...state.catalog.filter(i => i.type === 'QUESTION')]
			return questions
		},
		searchProducts: state => keyword => {
			if (keyword.length === 0) return []
			keyword = keyword.toUpperCase()
			const fields = ['brandName', 'categoryName', 'sku', 'name', 'nameSc', 'nameEn', 'description']
			const products = [
				...state.catalog.filter(p => {
					let flag = false
					if (p.type === 'PRODUCT') {
						fields.forEach(f => {
							if (p[f] && p[f].toUpperCase().indexOf(keyword) > -1) return (flag = true)
						})
					}
					if (flag) return p
				}),
			]
			return products
		},
		inWishlist: state => id => {
			if (!state.customer) return false
			const index = state.wishlist.findIndex(p => p.id === id)
			if (index !== -1) return true
			return false
		},
		inCartProduct: state => id => {
			if (!state.customer) return false
			const product = state.cart.products.find(p => p.id === id)
			if (product) return product
			return false
		},
		cartProductQuantity: state => id => {
			if (!state.customer) return 0
			const product = state.cart.products.find(p => p.id === id)
			if (product) return product.quantity
			return 0
		},
		inCartDiscount: state => id => {
			const index = state.cart.discounts.findIndex(d => d.id === id)
			if (index !== -1) return true
			return false
		},
	},
})
