const { sendApi } = require('./base.api')

const createStaff = async (staffRequest = {}) => await sendApi('post', `/staffs/create`, staffRequest)
const batchGetStaffs = async (staffRequest = {}) => await sendApi('post', `/staffs/batchGet`, staffRequest)
const getStaff = async (id = ``) => await sendApi('post', `/staffs/${id}/get`)
const updateStaff = async (id = ``, staffRequest = {}) => await sendApi('post', `/staffs/${id}/update`, staffRequest)
const deleteStaff = async (id = ``) => await sendApi('post', `/staffs/${id}/delete`)

module.exports = {
	createStaff,
	batchGetStaffs,
	getStaff,
	updateStaff,
	deleteStaff,
}
