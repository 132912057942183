export function SaleOrder() {
	return {
		id: null,
		referenceId: null,
		customer: null,
		packingStaff: null,
		shippingStaff: null,
		shipment: {
			type: 'DELIVERY',
			deliveryType: 'MYJETMALL_BAC_DELIVERY',
			date: '',
			time: '',
			address: null,
		},
		products: [],
		discounts: [],
		services: [],
		productTotal: 0,
		discountTotal: 0,
		serviceTotal: 0,
		usedPoints: 0,
		earnedPoints: 0,
		grandTotal: 0,
		payment: null,
		paymentCount: 0,
		remark: '',
		status: 'DRAFT',
		createdAt: Date.now(),
		updatedAt: Date.now(),
	}
}