<template>
	<div class="fixed bottom-0 z-30 w-full bg-white px-5" :class="classes">
		<slot />
	</div>
</template>

<script setup>
import { storeToRefs } from 'pinia'
import { useSharedStore } from '@/store/shared'
import { reactive, computed } from 'vue'

const { config } = storeToRefs(useSharedStore())

// const props = defineProps({
// 	platform: {
// 		default: 'web',
// 	},
// })

const classNameMap = reactive({
	platform: {
		ios: 'pb-safe',
		web: 'pb-4',
		android: 'pb-4',
	},
})

const classes = computed(() => {
	const result = []
	if (config.value.platform) result.push(classNameMap.platform[config.value.platform])
	return result.join(' ')
})
</script>
