const { sendApi } = require('./base.api')

const getSaleReport = async (reportRequest = {}) => await sendApi('post', `/reports/getSaleReport`, reportRequest)
const getPurchaseReport = async (reportRequest = {}) => await sendApi('post', `/reports/getPurchaseReport`, reportRequest)
const getStaffReport = async (reportRequest = {}) => await sendApi('post', `/reports/getStaffReport`, reportRequest)
const getCustomerReport = async (reportRequest = {}) => await sendApi('post', `/reports/getCustomerReport`, reportRequest)
const getExpenseReport = async (reportRequest = {}) => await sendApi('post', `/reports/getExpenseReport`, reportRequest)
const getPunchReport = async (reportRequest = {}) => await sendApi('post', `/reports/getPunchReport`, reportRequest)

module.exports = {
	getSaleReport,
	getPurchaseReport,
	getStaffReport,
	getCustomerReport,
	getExpenseReport,
	getPunchReport,
}
