const { sendApi } = require('./base.api')

const openShift = async (shiftRequest = {}) => await sendApi('post', `/shifts/open`, shiftRequest)
const batchGetShifts = async (shiftRequest = {}) => await sendApi('post', `/shifts/batchGet`, shiftRequest)
const getShift = async (id = ``) => await sendApi('post', `/shifts/${id}/get`)
const closeShift = async (id = ``, shiftRequest = {}) => await sendApi('post', `/shifts/${id}/close`, shiftRequest)

module.exports = {
	openShift,
	batchGetShifts,
	getShift,
	closeShift,
}
