import { defineStore, storeToRefs } from 'pinia'
import { query, collection } from 'firebase/firestore'
import { ref, computed } from 'vue'
import { useSharedStore } from './shared'
import { db } from '@/firebase'
// import { useFirebaseStore } from './firebase'

export const useCatalogStore = defineStore('catalogStore', () => {
	// otherStore
	// const { db } = useFirebaseStore()

	const { currentLang, bind, showLoading } = storeToRefs(useSharedStore())
	const { bindCollection } = useSharedStore()

	// catalog
	function bindCatalog() {
		showLoading.value = true
		bindCollection({ key: 'menus', ref: query(collection(db, 'menus')) })
		bindCollection({ key: 'categories', ref: query(collection(db, 'categories')) })
		bindCollection({ key: 'products', ref: query(collection(db, 'products')) })
		bindCollection({ key: 'variants', ref: query(collection(db, 'variants')) })
		bindCollection({ key: 'discounts', ref: query(collection(db, 'discounts')) })
		bindCollection({ key: 'vouchers', ref: query(collection(db, 'vouchers')) })
		showLoading.value = false
	}

	const menus = computed(() => bind.value.menus)
	const categories = computed(() => bind.value.categories)
	const products = computed(() => bind.value.products)
	const variants = computed(() => bind.value.variants)
	const extras = computed(() => bind.value.extras)
	const items = computed(() => bind.value.items)
	const discounts = computed(() => bind.value.discounts)
	const vouchers = computed(() => bind.value.vouchers)

	const catalog = computed(() => {
		console.time('computedCatalog')
		showLoading.value = true
		let result = []
		// 加入類別
		menus.value.forEach(menu => {
			menu.categories = []
			menu.categoryIds.forEach(categoryId => {
				categories.value.find(category => {
					if (category.id !== categoryId) return
					menu.categories.push(category)
				})
			})
			// 加入商品
			menu.categories.forEach(category => {
				category.products = []
				category.productIds.forEach(productId => {
					products.value.find(product => {
						if (product.id !== productId) return
						category.products.push(product)
					})
				})
				// 加入變化
				category.products.forEach(product => {
					product.variants = []
					product.variantIds.forEach(variantId => {
						variants.value.find(variant => {
							if (variant.id !== variantId) return
							product.variants.push(variant)
						})
					})
				}) //  變化 end
			}) //  商品 end
			result.push(menu)
		}) //  類別 end
		showLoading.value = false
		console.timeEnd('computedCatalog')
		return result
	})

	function getProduct(val) {
		let result = null
		catalog.value.forEach(c =>
			c.categories.forEach(category =>
				category.products.find(p => {
					if (p.id !== val.id) return
					result = p
				})
			)
		)
		return result
	}

	function searchProducts(keyword) {
		console.log(keyword)
		if (keyword.length === 0) return []
		keyword = keyword.toUpperCase()
		const fields = ['name']
		const products = []
		catalog.value.forEach(c =>
			c.categories.forEach(category =>
				category.products.filter(p => {
					console.log(p)
					console.log(fields)
					let flag = false
					// if (p.type === 'PRODUCT') {
					fields.forEach(f => {
						// console.log(p[f][bind.value.currentLang])
						if (p[f][currentLang.value] && p[f][currentLang.value].toUpperCase().indexOf(keyword) > -1) return (flag = true)
					})
					// }
					if (flag) products.push(p)
				})
			)
		)
		console.log(products)

		return products
	}
	// const vouchers = computed(() => {
	// 	console.time('computedVouchers')
	// 	showLoading.value = true
	// 	let result = []
	// 	bind.value.vouchers.forEach(voucher => {
	// 		discounts.value.find(discount => {
	// 			if (discount.id !== voucher.discountId) return
	// 			voucher.discount = discount
	// 		})
	// 		result.push(voucher)
	// 	})
	// 	return result
	// })

	const category = ref()
	const product = ref()
	const voucher = ref()

	const categoriesInMenus = computed(() => {
		showLoading.value = true
		let result = []
		menus.value.forEach(menu => {
			menu.categoryIds.forEach(categoryId => {
				categories.value.find(category => {
					if (category.id !== categoryId) return
					result.push(category)
				})
			})
		})
		showLoading.value = false
		return result
	})

	const productsByCategoryId = id =>
		computed(() => {
			showLoading.value = true
			let category = categories.value.find(category => category.id === id)
			let result = []

			category.productIds.forEach(categoryId => {
				products.value.find(product => {
					if (product.id !== categoryId) return
					result.push(product)
				})
			})

			showLoading.value = false
			return result
		})
	const productsInCategories = computed(() => products.value.filter(product => categories.value.find(category => category.productIds.includes(product.id))))

	function variantsByProductId(id) {
		showLoading.value = true
		let product = products.value.find(product => product.id === id)
		let result = []
		product.variantIds.forEach(variantId => {
			variants.value.find(variant => {
				if (variant.id !== variantId) return
				result.push(variant)
			})
		})
		showLoading.value = false
		return result
	}

	const extrasByVariantId = id => computed(() => extras.value.filter(extra => variants.value.find(variant => variant.id === id && variant.extraIds.includes(extra.id))))
	const itemsByExtraId = id => computed(() => items.value.filter(item => extras.value.find(extra => extra.id === id && extra.itemIds.includes(item.id))))
	const getMenuById = id => computed(() => menus.value.find(menu => menu.id === id))
	// const getCatalogById = id => computed(() => catalog.value.find(c => c.id === id))
	function getCategoryById(id) {
		return categories.value.find(category => category.id === id)
	}
	// const getCategoryById = id => computed(() => categories.value.find(category => category.id === id))
	const getProductById = id => computed(() => products.value.find(product => product.id === id))
	const getVariantById = id => computed(() => variants.value.find(variant => variant.id === id))

	const getDiscountByCode = code => computed(() => discounts.value.find(discount => discount.code == code))

	function getProductByQuickCode(quickCode) {
		let result = false
		let variant = null
		let product = null
		variant = variants.value.find(variant => variant.quickCode === quickCode)
		if (variant) {
			product = products.value.find(product => product.variantIds.includes(variant.id))
			if (product) result = true
		}

		return { result, variant, product }
	}

	function getProductByBarcode(barcode) {
		let result = false
		let variant = null
		let product = null
		variant = variants.value.find(variant => variant.barcode === barcode)
		if (variant) {
			product = products.value.find(product => product.variantIds.includes(variant.id))
			if (product) result = true
		}

		return { result, variant, product }
	}

	return {
		catalog,
		category,
		product,
		categoriesInMenus,
		productsInCategories,
		discounts,
		vouchers,
		voucher,
		getMenuById,
		getCategoryById,
		getProduct,
		getProductById,
		getVariantById,
		bindCatalog,
		// getCatalogById,
		getProductByBarcode,
		getProductByQuickCode,
		getDiscountByCode,
		productsByCategoryId,
		variantsByProductId,
		extrasByVariantId,
		itemsByExtraId,
		searchProducts,
	}
})
