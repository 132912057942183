<template>
	<BaseLayout class="fixed top-0 z-75">
		<Toolbar :variant="variant ? variant : 'white'" @clickLeft="$emit('close')">
			<template v-slot:start>
				<Icon class="h-5 w-5" icon="xmark" />
			</template>
			{{ title }}
			<slot name="search" />
		</Toolbar>

		<slot />
	</BaseLayout>
</template>

<script>
export default {
	props: ['title', 'variant'],
	emits: ['close'],
}
</script>
