const { sendApi } = require('./base.api')

const createStock = async (stockRequest = {}) => await sendApi('post', `/stocks/create`, stockRequest)
const batchGetStocks = async (stockRequest = {}) => await sendApi('post', `/stocks/batchGet`, stockRequest)
const getStock = async (id = ``) => await sendApi('post', `/stocks/${id}/get`)
const updateStock = async (id = ``, stockRequest = {}) => await sendApi('post', `/stocks/${id}/update`, stockRequest)
const deleteStock = async (id = ``) => await sendApi('post', `/stocks/${id}/delete`)

module.exports = {
	createStock,
	batchGetStocks,
	getStock,
	updateStock,
	deleteStock,
}
