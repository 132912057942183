const { sendApi } = require('./base.api')

const createLevel = async (levelRequest = {}) => await sendApi('post', `/levels/create`, levelRequest)
const batchGetLevels = async (levelRequest = {}) => await sendApi('post', `/levels/batchGet`, levelRequest)
const getLevel = async (id = ``) => await sendApi('post', `/levels/${id}/get`)
const updateLevel = async (id = ``, levelRequest = {}) => await sendApi('post', `/levels/${id}/update`, levelRequest)
const deleteLevel = async (id = ``) => await sendApi('post', `/levels/${id}/delete`)

module.exports = {
	createLevel,
	batchGetLevels,
	getLevel,
	updateLevel,
	deleteLevel,
}
