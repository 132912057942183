const { sendApi } = require('./base.api')

const createPrinter = async (printerRequest = {}) => await sendApi('post', `/printers/create`, printerRequest)
const batchGetPrinters = async (printerRequest = {}) => await sendApi('post', `/printers/batchGet`, printerRequest)
const getPrinter = async (id = ``) => await sendApi('post', `/printers/${id}/get`)
const updatePrinter = async (id = ``, printerRequest = {}) => await sendApi('post', `/printers/${id}/update`, printerRequest)
const deletePrinter = async (id = ``) => await sendApi('post', `/printers/${id}/delete`)

module.exports = {
	createPrinter,
	batchGetPrinters,
	getPrinter,
	updatePrinter,
	deletePrinter,
}
