import { defineStore, storeToRefs } from 'pinia'
import { computed } from 'vue'
import { useSharedStore } from './shared'

export const useShopStore = defineStore('shopStore', () => {
	// state
	const { currentLang, bind } = storeToRefs(useSharedStore())
	const { verifyDoc } = useSharedStore()

	// shops
	async function verifyShop(id) {
		return await verifyDoc('currentShop', 'shops', id)
	}

	const currentShop = computed(() => bind.value.currentShop)

	const currentShopSaleMethods = computed(() => currentShop.value?.saleSetting.methods)

	function verifySaleMethod(val, type) {
		switch (type) {
			case 'DELIVERY':
				return currentShop.value.deliverySetting.appTypes.includes(val)
			case 'PICKUP':
				return currentShop.value.pickupSetting.appTypes.includes(val)
			case 'ONSITE':
				return currentShop.value.onsiteSetting.appTypes.includes(val)
		}
		// return currentShop.value.pickupSetting.appTypes.includes(val)
	}

	const currentShopName = computed(() => currentShop.value.name[currentLang.value])

	return {
		currentShop,
		currentShopName,
		currentShopSaleMethods,
		verifyShop,
		verifySaleMethod,
	}
})
