const { sendApi } = require('./base.api')

const createVendor = async (vendorRequest = {}) => await sendApi('post', `/vendors/create`, vendorRequest)
const batchGetVendors = async (vendorRequest = {}) => await sendApi('post', `/vendors/batchGet`, vendorRequest)
const getVendor = async (id = ``) => await sendApi('post', `/vendors/${id}/get`)
const updateVendor = async (id = ``, vendorRequest = {}) => await sendApi('post', `/vendors/${id}/update`, vendorRequest)
const deleteVendor = async (id = ``) => await sendApi('post', `/vendors/${id}/delete`)

module.exports = {
	createVendor,
	batchGetVendors,
	getVendor,
	updateVendor,
	deleteVendor,
}
