const axios = require('axios').default

async function sendApi(method, uri, data) {
	try {
		const response = await axios({ url: process.env.VUE_APP_BASE_URL + uri, method, data })
		return response.data
	} catch (error) {
		console.error(error)
		if (error.response) throw { message: error.response.data.message }
		if (error) throw { message: error.message }
		throw { message: error }
	}
}

module.exports = {
	sendApi,
}
