<template>
    <button class="flex py-3 w-full gap-2 rounded-xl justify-center items-center" :class="classNameMap[variant]">
        <div v-if="loading">{{ $t('button.loading') }}</div>
        <slot v-else />
    </button>
</template>

<script>
import { ref } from 'vue'

export default {
    props: {
        variant: {
            default: 'primary',
        },
        loading: Boolean,
    },

    setup() {
        const classNameMap = ref({
            primary: 'bg-primary text-white ',
            white: 'bg-white text-primary',
            danger: 'bg-white border-red-400 border-2 text-red-400',
        })

        return {
            classNameMap,
        }
    }

}
</script>