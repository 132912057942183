const { sendApi } = require('./base.api')

const createProduct = async (productRequest = {}) => await sendApi('post', `/products/create`, productRequest)
const batchGetProducts = async (productRequest = {}) => await sendApi('post', `/products/batchGet`, productRequest)
const getProduct = async (id = ``) => await sendApi('post', `/products/${id}/get`)
const updateProduct = async (id = ``, productRequest = {}) => await sendApi('post', `/products/${id}/update`, productRequest)
const deleteProduct = async (id = ``) => await sendApi('post', `/products/${id}/delete`)

module.exports = {
	createProduct,
	batchGetProducts,
	getProduct,
	updateProduct,
	deleteProduct,
}
