const { sendApi } = require('./base.api')

const createShop = async (shopRequest = {}) => await sendApi('post', `/shops/create`, shopRequest)
const batchGetShops = async (shopRequest = {}) => await sendApi('post', `/shops/batchGet`, shopRequest)
const getShop = async (id = ``) => await sendApi('post', `/shops/${id}/get`)
const updateShop = async (id = ``, shopRequest = {}) => await sendApi('post', `/shops/${id}/update`, shopRequest)
const deleteShop = async (id = ``) => await sendApi('post', `/shops/${id}/delete`)

module.exports = {
	createShop,
	batchGetShops,
	getShop,
	updateShop,
	deleteShop,
}
