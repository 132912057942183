const { sendApi } = require('./base.api')

// 盤點訂單
const draftAdjustment = async (adjustmentRequest = {}) => await sendApi('post', `/adjustments/draft`, adjustmentRequest)
const batchGetAdjustments = async (adjustmentRequest = {}) => await sendApi('post', `/adjustments/batchGet`, adjustmentRequest)
const getAdjustment = async (id = ``) => await sendApi('post', `/adjustments/${id}/get`)
const openAdjustment = async (id = ``, adjustmentRequest = {}) => await sendApi('post', `/adjustments/${id}/open`, adjustmentRequest)
const countAdjustment = async (id = ``, adjustmentRequest = {}) => await sendApi('post', `/adjustments/${id}/count`, adjustmentRequest)
const closeAdjustment = async (id = ``, adjustmentRequest = {}) => await sendApi('post', `/adjustments/${id}/close`, adjustmentRequest)
const cancelAdjustment = async (id = ``, adjustmentRequest = {}) => await sendApi('post', `/adjustments/${id}/cancel`, adjustmentRequest)
const updateAdjustmentRemark = async (id = ``, adjustmentRequest = {}) => await sendApi('post', `/adjustments/${id}/remark/update`, adjustmentRequest)

// 盤點訂單商品
const createAdjustmentProduct = async (id = ``, adjustmentProductRequest = {}) => await sendApi('post', `/adjustments/${id}/products/create`, adjustmentProductRequest)
const updateAdjustmentProduct = async (id = ``, index = -1, adjustmentProductRequest = {}) => await sendApi('post', `/adjustments/${id}/products/${index}/update`, adjustmentProductRequest)
const cancelAdjustmentProduct = async (id = ``, index = -1) => await sendApi('post', `/adjustments/${id}/products/${index}/cancel`)

module.exports = {
	draftAdjustment,
	batchGetAdjustments,
	getAdjustment,
	openAdjustment,
	countAdjustment,
	closeAdjustment,
	cancelAdjustment,
	updateAdjustmentRemark,
	createAdjustmentProduct,
	updateAdjustmentProduct,
	cancelAdjustmentProduct,
}
