const { sendApi } = require('./base.api')

const createCategory = async (categoryRequest = {}) => await sendApi('post', `/categories/create`, categoryRequest)
const batchGetCategories = async (categoryRequest = {}) => await sendApi('post', `/categories/batchGet`, categoryRequest)
const getCategory = async (id = ``) => await sendApi('post', `/categories/${id}/get`)
const updateCategory = async (id = ``, categoryRequest = {}) => await sendApi('post', `/categories/${id}/update`, categoryRequest)
const deleteCategory = async (id = ``) => await sendApi('post', `/categories/${id}/delete`)

module.exports = {
	createCategory,
	batchGetCategories,
	getCategory,
	updateCategory,
	deleteCategory,
}
