const { sendApi } = require('./base.api')

const createDiscount = async (discountRequest = {}) => await sendApi('post', `/discounts/create`, discountRequest)
const batchGetDiscounts = async (discountRequest = {}) => await sendApi('post', `/discounts/batchGet`, discountRequest)
const getDiscount = async (id = ``) => await sendApi('post', `/discounts/${id}/get`)
const updateDiscount = async (id = ``, discountRequest = {}) => await sendApi('post', `/discounts/${id}/update`, discountRequest)
const deleteDiscount = async (id = ``) => await sendApi('post', `/discounts/${id}/delete`)

module.exports = {
	createDiscount,
	batchGetDiscounts,
	getDiscount,
	updateDiscount,
	deleteDiscount,
}
