<template>
	<p class="bg-light-grey px-5 -mx-5 py-2 text-sm text-secondary">{{ title }}</p>
	<slot />

</template>

<script>
export default {
	props: ['title']
}
</script>
