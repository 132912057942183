const shopApi = require('./apis/shop.api')
const terminalApi = require('./apis/terminal.api')
const printerApi = require('./apis/printer.api')

const menuApi = require('./apis/menu.api')
const brandApi = require('./apis/brand.api')
const categoryApi = require('./apis/category.api')
const productApi = require('./apis/product.api')
const variantApi = require('./apis/variant.api')
const discountApi = require('./apis/discount.api')
const surchargeApi = require('./apis/surcharge.api')

const staffApi = require('./apis/staff.api')
const departmentApi = require('./apis/department.api')
const roleApi = require('./apis/role.api')
const scheduleApi = require('./apis/schedule.api')
const leaveApi = require('./apis/leave.api')
const punchApi = require('./apis/punch.api')

const customerApi = require('./apis/customer.api')
const levelApi = require('./apis/level.api')
const voucherApi = require('./apis/voucher.api')

const stockApi = require('./apis/stock.api')
const vendorApi = require('./apis/vendor.api')
const purchaseApi = require('./apis/purchase.api')
const adjustmentApi = require('./apis/adjustment.api')
const transferApi = require('./apis/transfer.api')

const expenseApi = require('./apis/expense.api')

const shiftApi = require('./apis/shift.api')
const saleApi = require('./apis/sale.api')

const reportApi = require('./apis/report.api')

const noticeApi = require('./apis/notice.api')

module.exports = {
	shopApi,
	terminalApi,
	printerApi,

	menuApi,
	brandApi,
	categoryApi,
	productApi,
	variantApi,
	discountApi,
	surchargeApi,

	staffApi,
	departmentApi,
	roleApi,
	scheduleApi,
	leaveApi,
	punchApi,

	customerApi,
	levelApi,
	voucherApi,

	stockApi,
	vendorApi,
	purchaseApi,
	adjustmentApi,
	transferApi,

	shiftApi,
	saleApi,

	expenseApi,

	reportApi,

	noticeApi,
}
