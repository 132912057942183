const { sendApi } = require('./base.api')

// 銷售訂單
const draftSale = async (saleRequest = {}) => await sendApi('post', `/sales/draft`, saleRequest)
const batchGetSales = async (saleRequest = {}) => await sendApi('post', `/sales/batchGet`, saleRequest)
const getSale = async (id = ``) => await sendApi('post', `/sales/${id}/get`)
const openSale = async (id = ``, saleRequest = {}) => await sendApi('post', `/sales/${id}/open`, saleRequest)
const receiveSale = async (id = ``, saleRequest = {}) => await sendApi('post', `/sales/${id}/receive`, saleRequest)
const packSale = async (id = ``, saleRequest = {}) => await sendApi('post', `/sales/${id}/pack`, saleRequest)
const deliverSale = async (id = ``, saleRequest = {}) => await sendApi('post', `/sales/${id}/deliver`, saleRequest)
const closeSale = async (id = ``, saleRequest = {}) => await sendApi('post', `/sales/${id}/close`, saleRequest)
const cancelSale = async (id = ``, saleRequest = {}) => await sendApi('post', `/sales/${id}/cancel`, saleRequest)
const updateSaleMethod = async (id = ``, saleRequest = {}) => await sendApi('post', `/sales/${id}/method/update`, saleRequest)
const updateSalePickupSetting = async (id = ``, saleRequest = {}) => await sendApi('post', `/sales/${id}/pickupSetting/update`, saleRequest)
const updateSaleDeliverySetting = async (id = ``, saleRequest = {}) => await sendApi('post', `/sales/${id}/deliverySetting/update`, saleRequest)
const updateSaleUsedPoints = async (id = ``, saleRequest = {}) => await sendApi('post', `/sales/${id}/usedPoints/update`, saleRequest)
const updateSaleRemark = async (id = ``, saleRequest = {}) => await sendApi('post', `/sales/${id}/remark/update`, saleRequest)

// 銷售訂單商品
const createSaleProduct = async (id = ``, saleProductRequest = {}) => await sendApi('post', `/sales/${id}/products/create`, saleProductRequest)
const updateSaleProduct = async (id = ``, index = -1, saleProductRequest = {}) => await sendApi('post', `/sales/${id}/products/${index}/update`, saleProductRequest)
const cancelSaleProduct = async (id = ``, index = -1) => await sendApi('post', `/sales/${id}/products/${index}/cancel`)

// 銷售訂單優惠券
const createSaleVoucher = async (id = ``, saleVoucherRequest = {}) => await sendApi('post', `/sales/${id}/vouchers/create`, saleVoucherRequest)
const cancelSaleVoucher = async (id = ``, index = -1) => await sendApi('post', `/sales/${id}/vouchers/${index}/cancel`)

// 銷售訂單優惠
const createSaleDiscount = async (id = ``, saleDiscountRequest = {}) => await sendApi('post', `/sales/${id}/discounts/create`, saleDiscountRequest)
const cancelSaleDiscount = async (id = ``, index = -1) => await sendApi('post', `/sales/${id}/discounts/${index}/cancel`)

// 銷售訂單付款
const createSalePayment = async (id = ``, salePaymentRequest = {}) => await sendApi('post', `/sales/${id}/payments/create`, salePaymentRequest)
const cancelSalePayment = async (id = ``, index = -1) => await sendApi('post', `/sales/${id}/payments/${index}/cancel`)

// 發送銷售訂單收據
const sendSaleReceipt = async (id = ``, saleProductRequest = {}) => await sendApi('post', `/sales/${id}/sendSaleReceipt`, saleProductRequest)

// PayPal付款
const paypalCheckout = async (id = ``) => await sendApi('post', `/sales/${id}/paypalCheckout`)
const paypalCapture = async (id = ``, saleRequest = {}) => await sendApi('post', `/sales/${id}/paypalCapture`, saleRequest)

module.exports = {
	draftSale,
	batchGetSales,
	getSale,
	openSale,
	receiveSale,
	packSale,
	deliverSale,
	closeSale,
	cancelSale,
	updateSaleMethod,
	updateSalePickupSetting,
	updateSaleDeliverySetting,
	updateSaleUsedPoints,
	updateSaleRemark,
	createSaleProduct,
	updateSaleProduct,
	cancelSaleProduct,
	createSaleVoucher,
	cancelSaleVoucher,
	createSaleDiscount,
	cancelSaleDiscount,
	createSalePayment,
	cancelSalePayment,
	sendSaleReceipt,
	paypalCheckout,
	paypalCapture,
}
