const { sendApi } = require('./base.api')

const createVoucher = async (voucherRequest = {}) => await sendApi('post', `/vouchers/create`, voucherRequest)
const batchGetVouchers = async (voucherRequest = {}) => await sendApi('post', `/vouchers/batchGet`, voucherRequest)
const redeemVoucher = async (voucherRequest = {}) => await sendApi('post', `/vouchers/redeem`, voucherRequest)
const getVoucher = async (id = ``) => await sendApi('post', `/vouchers/${id}/get`)
const updateVoucher = async (id = ``, voucherRequest = {}) => await sendApi('post', `/vouchers/${id}/update`, voucherRequest)
const deleteVoucher = async (id = ``) => await sendApi('post', `/vouchers/${id}/delete`)

module.exports = {
	createVoucher,
	batchGetVouchers,
	redeemVoucher,
	getVoucher,
	updateVoucher,
	deleteVoucher,
}
