import { defineStore, storeToRefs } from 'pinia'
import { query, collection, where, orderBy, getDoc, doc } from 'firebase/firestore'
import { computed } from 'vue'
import { useSharedStore } from './shared'
// import { useFirebaseStore } from './firebase'
import { db } from '@/firebase'

export const useSaleStore = defineStore('saleStore', () => {
	// state
	// const { db } = useFirebaseStore()

	const { bind } = storeToRefs(useSharedStore())
	const { bindCollection, bindDoc, verifyDoc } = useSharedStore()
	const sales = computed(() => bind.value.sales)
	const sale = computed(() => bind.value.currentSale)

	const preOrders = computed(() => bind.value.preOrders)
	const preOrder = computed(() => bind.value.currentPreOrder)

	const days = computed(() => bind.value.days)
	const shifts = computed(() => bind.value.shifts)
	const drawers = computed(() => bind.value.drawers)

	const currentDay = computed(() => bind.value.currentDay)
	const currentShift = computed(() => bind.value.currentShift)
	const currentDrawer = computed(() => bind.value.currentDrawer)

	// sale
	function batchGetSalebyCustomeId(customerId, shopId) {
		bindCollection({ key: 'sales', ref: query(collection(db, 'sales'), orderBy('referenceNumber', 'desc'), where('customer.id', '==', customerId), where('shop.id', '==', shopId)) })
	}

	function getCurrentSale(saleId) {
		bindDoc('currentSale', 'sales', saleId)
	}

	async function verifySale(id) {
		const docRef = doc(db, 'sales', id)
		const docSnap = await getDoc(docRef)
		if (docSnap.exists() && verifySaleStatus(docSnap.data().status)) {
			bindDoc('currentSale', 'sales', id)
			return true
		}
		return false
	}

	function verifySaleStatus(status) {
		return ['CLOSED', 'VOIDED', 'CANCELED'].some(val => status === val)
	}

	// preOrder
	async function verifyPreOrder(id) {
		return await verifyDoc('currentPreOrder', 'sales', id)
	}

	return {
		sales,
		sale,
		preOrders,
		preOrder,
		days,
		shifts,
		drawers,
		currentDay,
		currentShift,
		currentDrawer,
		verifySale,
		verifyPreOrder,
		batchGetSalebyCustomeId,
		getCurrentSale,
	}
})
