import { defineStore, storeToRefs } from 'pinia'
import { ref, computed } from 'vue'
import { auth, db } from '@/firebase'
import { query, collection, where, setDoc, doc, runTransaction } from 'firebase/firestore'
import { Message } from '@/models/chat'
import { signInWithEmailAndPassword, signOut } from 'firebase/auth'
import { useSharedStore } from './shared'
import { useCatalogStore } from './catalog'
import { customerApi } from 'gox-sdk'
import { FirebaseAuthentication } from '@capacitor-firebase/authentication'
import { Capacitor } from '@capacitor/core'

export const useCustomerStore = defineStore('customerStore', () => {
	const { bind, showLoading } = storeToRefs(useSharedStore())
	const { bindDoc, bindCollection, unbindDoc, unbindCollection, showAlert } = useSharedStore()
	const { catalog } = storeToRefs(useCatalogStore())
	const currentCustomer = computed(() => bind.value.currentCustomer)
	const currentCustomerLevel = computed(() => bind.value.currentCustomerLevel)
	const currentAddress = ref()

	async function signInWithEmail(emailAddress, password) {
		console.log('signInWithEmail', auth, emailAddress, password)
		let userCredential = null
		if (Capacitor.getPlatform() === `web`) {
			userCredential = await signInWithEmailAndPassword(auth, emailAddress, password)
		} else {
			userCredential = await FirebaseAuthentication.signInWithEmailAndPassword({ email: emailAddress, password: password })
		}
		if (!userCredential) return
		const uid = userCredential.user.uid
		console.log('signInWithEmailResult', userCredential)
		const customer = await customerApi.getCustomer(uid)
		if (customer) {
			await verifyCustomer('signInWithEmail')
			return true
		}
	}

	const signInWithApple = async () => {
		console.log('signInWithApple')
		const result = await FirebaseAuthentication.signInWithApple()
		if (!result) return false
		const uid = result.user.uid
		const email = result.user.email
		const phoneNumber = result.user.phoneNumber
		console.log('signInWithAppleResult', result)
		try {
			await customerApi.getCustomer(uid)
			await verifyCustomer('signInWithApple')
			return true
		} catch (e) {
			console.log('appleUID', uid)
			const createCustomerRequest = {
				id: uid,
				firstName: '',
				lastName: '',
				gender: 'X',
				birthday: '',
				emailAddress: email ?? '',
				phoneNumber: phoneNumber ?? '',
				accumulatedPoints: 0,
				pointBalance: 0,
				favoriteProductIds: [],
				deliveryAddresses: [],
				billingAddresses: [],
				isSmsEnabled: false,
				isWhatsappEnabled: false,
				isEmailEnabled: false,
				status: 'ACTIVE',
			}
			try {
				await customerApi.createCustomer(createCustomerRequest)
				return true
			} catch (e) {
				return false
			}
		}
	}

	const signInWithFacebook = async () => {
		console.log('signInWithFacebook')
		const result = await FirebaseAuthentication.signInWithFacebook()
		if (!result) return false
		const uid = result.user.uid
		const email = result.user.email
		const phoneNumber = result.user.phoneNumber
		console.log('signInWithFacebookResult', result)
		try {
			await customerApi.getCustomer(uid)
			await verifyCustomer('signInWithFacebook')
			return true
		} catch (e) {
			console.log('facebookUID', uid)
			const createCustomerRequest = {
				id: uid,
				firstName: '',
				lastName: '',
				gender: 'X',
				birthday: '',
				emailAddress: email ?? '',
				phoneNumber: phoneNumber ?? '',
				accumulatedPoints: 0,
				pointBalance: 0,
				favoriteProductIds: [],
				deliveryAddresses: [],
				billingAddresses: [],
				isSmsEnabled: false,
				isWhatsappEnabled: false,
				isEmailEnabled: false,
				status: 'ACTIVE',
			}
			try {
				await customerApi.createCustomer(createCustomerRequest)
				return true
			} catch (e) {
				return false
			}
		}
	}

	const signInWithGoogle = async () => {
		console.log('signInWithGoogle')
		const result = await FirebaseAuthentication.signInWithGoogle()
		if (!result) return false
		const uid = result.user.uid
		const email = result.user.email
		const phoneNumber = result.user.phoneNumber
		console.log('signInWithGoogleResult', result)
		try {
			await customerApi.getCustomer(uid)
			await verifyCustomer('signInWithGoogle')
			return true
		} catch (e) {
			console.log('googleUID', uid)
			const createCustomerRequest = {
				id: uid,
				firstName: '',
				lastName: '',
				gender: 'X',
				birthday: '',
				emailAddress: email ?? '',
				phoneNumber: phoneNumber ?? '',
				accumulatedPoints: 0,
				pointBalance: 0,
				favoriteProductIds: [],
				deliveryAddresses: [],
				billingAddresses: [],
				isSmsEnabled: false,
				isWhatsappEnabled: false,
				isEmailEnabled: false,
				status: 'ACTIVE',
			}
			try {
				await customerApi.createCustomer(createCustomerRequest)
				return true
			} catch (e) {
				return false
			}
		}
	}

	async function signInWithPhone(areaCode, phoneNumber, vid, vcode) {
		console.log('signInWithPhone', auth, vid, vcode)
		let userCredential = null
		if (Capacitor.getPlatform() === `web`) userCredential = await vid.confirm(vcode)
		else userCredential = await FirebaseAuthentication.signInWithPhoneNumber({ verificationId: vid, verificationCode: vcode })
		if (!userCredential) return
		const uid = userCredential.user.uid
		console.log('signInWithPhoneResult', userCredential)
		await customerApi.getCustomer(uid)
		await verifyCustomer('signInWithPhone')
		return true
	}

	async function registerWithEmail(emailAddress, password) {
		const createCustomerRequest = {
			firstName: '',
			lastName: '',
			emailAddress: emailAddress,
			gender: 'X',
			birthday: '',
			phoneNumber: '',
			accumulatedPoints: 0,
			pointBalance: 0,
			favoriteProductIds: [],
			deliveryAddresses: [],
			billingAddresses: [],
			isSmsEnabled: false,
			isWhatsappEnabled: false,
			isEmailEnabled: false,
			status: 'ACTIVE',
			password: password,
		}
		const customer = await customerApi.createCustomer(createCustomerRequest)
		if (customer) {
			await signInWithEmail(emailAddress, password)
			return true
		}
	}

	async function registerWithPhone(areaCode, phoneNumber, vid, vcode) {
		console.log('registerWithPhone', auth, vid, vcode)
		let userCredential = null
		if (Capacitor.getPlatform() === `web`) userCredential = await vid.confirm(vcode)
		else userCredential = await FirebaseAuthentication.signInWithPhoneNumber({ verificationId: vid, verificationCode: vcode })
		if (!userCredential) return
		const uid = userCredential.user.uid
		console.log('registerWithPhoneResult', userCredential)
		let customer = null
		try {
			customer = await customerApi.getCustomer(uid)
		} catch (e) {
			const createCustomerRequest = {
				id: uid,
				firstName: '',
				lastName: '',
				gender: 'X',
				birthday: '',
				emailAddress: '',
				phoneNumber: areaCode + phoneNumber,
				accumulatedPoints: 0,
				pointBalance: 0,
				favoriteProductIds: [],
				deliveryAddresses: [],
				billingAddresses: [],
				isSmsEnabled: false,
				isWhatsappEnabled: false,
				isEmailEnabled: false,
				status: 'ACTIVE',
			}
			await customerApi.createCustomer(createCustomerRequest)
			await verifyCustomer(`registerWithPhone`)
			return true
		}
		if (customer) throw { message: 'Error (customer/already-exist)' }
		return true
	}

	async function verifyCustomer(val) {
		try {
			console.log('verifyCustomer', val)
			let user = null
			if (Capacitor.getPlatform() === `web`) {
				user = auth.currentUser
			} else {
				const result = await FirebaseAuthentication.getCurrentUser()
				user = result.user
			}
			if (!user) throw { code: 'auth/user-not-found', message: 'Error (auth/user-not-found).' }
			const customer = await customerApi.getCustomer(user.uid)
			if (customer) {
				// 綁定會員
				bindDoc('currentCustomer', 'customers', customer.id)
				// 綁定會員會籍
				bindDoc('currentCustomerLevel', 'levels', customer.levelId)
				// 綁定會員銷售
				bindCurrentCustomerSales(customer.id)
				bindCollection({ key: 'messages', ref: query(collection(db, 'chats', customer.id, 'messages')) })
				return true
			}
			throw { code: 'auth/customer-not-found', message: 'Error (auth/customer-not-found).' }
		} catch (e) {
			showLoading.value = false
			const message = `錯誤訊息: ${e.message ?? e}`
			showAlert({ message })
			return false
		}
	}

	function bindCurrentCustomerSales(customerId) {
		bindCollection({ key: 'sales', ref: query(collection(db, 'sales'), where('customer.id', '==', customerId)) })
	}

	function filterCurrentCustomerDraftSales(sales) {
		const draftSaleIndex = sales.findIndex(sale => sale.status === `DRAFTED`)
		if (draftSaleIndex === -1) return
		bindDoc('draftCart', 'sales', sales[draftSaleIndex].id)
		// if (draftSales.length > 1) throw 'draftSale 多於一張'
		// console.log('draftSalesLength', sales.length)
	}

	async function customerSignOut() {
		if (Capacitor.getPlatform() === `web`) {
			await signOut(auth)
		} else {
			await FirebaseAuthentication.signOut()
		}
		unbindDoc('currentCustomer')
		unbindCollection('messages')
		unbindDoc('currentCustomerLevel')
	}

	async function toggleWishlist(productId) {
		const wishlistIndex = inWishlist(productId)
		if (wishlistIndex >= 0) currentCustomer.value.favoriteProductIds.splice(wishlistIndex, 1)
		else currentCustomer.value.favoriteProductIds.push(productId)
		await customerApi.updateCustomer(currentCustomer.value.id, { favoriteProductIds: currentCustomer.value.favoriteProductIds })
	}
	async function createAddress(address) {
		// address.phoneNumber = address.areaCode + address.phoneNumber
		// delete address.areaCode
		currentCustomer.value.deliveryAddresses.push(address)
		await customerApi.updateCustomer(currentCustomer.value.id, { deliveryAddresses: currentCustomer.value.deliveryAddresses })
	}
	async function updateAddress(address, index) {
		// address.phoneNumber = address.areaCode + address.phoneNumber
		// delete address.areaCode
		currentCustomer.value.deliveryAddresses.splice(index, 1, address)
		await customerApi.updateCustomer(currentCustomer.value.id, { deliveryAddresses: currentCustomer.value.deliveryAddresses })
	}

	async function deleteAddress(index) {
		currentCustomer.value.deliveryAddresses.splice(index, 1)
		await customerApi.updateCustomer(currentCustomer.value.id, { deliveryAddresses: currentCustomer.value.deliveryAddresses })
	}

	function inWishlist(productId) {
		return currentCustomer.value?.favoriteProductIds.findIndex(favoriteProductId => favoriteProductId === productId)
	}

	const wishlist = computed(() => {
		const result = []
		currentCustomer.value.favoriteProductIds.forEach(favoriteProductId => {
			catalog.value.forEach(c =>
				c.categories.forEach(category =>
					category.products.filter(p => {
						if (p.id !== favoriteProductId) return
						result.push(p)
					})
				)
			)
		})
		return result
	})

	async function createMessage(content) {
		const message = { ...Message(), id: doc(collection(db, 'chats', currentCustomer.value.id, 'messages')).id, content: content }
		await setDoc(doc(db, 'chats', currentCustomer.value.id, 'messages', message.id), message)
		await runTransaction(db, async transaction => {
			const chatSnap = await transaction.get(doc(db, 'chats', currentCustomer.value.id))
			const chat = chatSnap.data()
			if (chat.lastMessage && chat.lastMessage.createdAt > message.createdAt) return
			transaction.update(doc(db, 'chats', currentCustomer.value.id), { lastMessage: message })
		})
	}

	return {
		signInWithApple,
		signInWithFacebook,
		signInWithGoogle,
		currentCustomerLevel,
		signInWithPhone,
		registerWithPhone,
		currentAddress,
		createAddress,
		updateAddress,
		deleteAddress,
		wishlist,
		filterCurrentCustomerDraftSales,
		currentCustomer,
		signInWithEmail,
		registerWithEmail,
		verifyCustomer,
		customerSignOut,
		inWishlist,
		toggleWishlist,
		createMessage,
	}
})
