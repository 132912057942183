<template>
	<Loading v-if="isLoading && $store.state.refreshState" :title="$t('alert.loading')" :refresh="$t('cart.refresh')" @onClick="capturePayment"></Loading>
	<Loading v-else-if="isLoading" :title="$t('alert.loading')"></Loading>
	<Toast v-else-if="error" @close="error = null">{{ error.message }}</Toast>
	<div class="mx-auto h-screen overflow-x-scroll disable-scrollbars">
		<router-view :key="$route.fullPath" />
	</div>
</template>

<script>
import { storeToRefs } from 'pinia'
import { ref, computed, onMounted, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { auth } from '@/firebase'
import { Device as CapacitorDevice } from '@capacitor/device'
import { useSharedStore } from '@/store/shared'
import { useCatalogStore } from '@/store/catalog'
import { useShopStore } from '@/store/shop'
import { useCustomerStore } from '@/store/customer'
import { useCartStore } from '@/store/cart'
import { useChatStore } from '@/store/chat'
import { useSaleStore } from '@/store/sale'
import { customerApi, saleApi } from 'gox-sdk'
import moment from 'moment'
import { FirebaseAuthentication } from '@capacitor-firebase/authentication'
import { Capacitor } from '@capacitor/core'

export default {
	setup() {
		const router = useRouter()
		const store = useStore()
		const { bindDoc, getJsonData } = useSharedStore()
		const { config, paypalData } = storeToRefs(useSharedStore())

		const { currentShop } = storeToRefs(useShopStore())
		const { bindCatalog } = useCatalogStore()

		const { verifyCustomer } = useCustomerStore()

		const { setCurrentCartMethod, resetCart } = useCartStore()
		const { currentCart } = storeToRefs(useCartStore())

		const { getCurrentSale } = useSaleStore()

		const { bindMessage } = useChatStore()
		const error = ref(null)
		const isLoading = computed(() => store.state.loadingState)
		// 檢查裝置平台
		const data = ref('')

		onMounted(async () => {
			bindDoc('currentShop', 'shops', 'q4eNQXGSdSpQyULtHVoZ')
			await getJsonData('questions', `https://nube.hk/FAQ2.json?timestamp=${moment()}`)
			await getJsonData('countryCode', `https://nube.hk/countryCode.json?timestamp=${moment()}`)
			await getJsonData('deliverySetting', `https://nube.hk/deliverySetting.json?timestamp=${moment()}`)
			await getJsonData('banners', `https://nube.hk/banner.json?timestamp=${moment()}`)
			await getJsonData('tnc', `https://nube.hk/TNC.json?timestamp=${moment()}`)
			await getJsonData('privacyPolicy', `https://nube.hk/PrivacyPolicy.json?timestamp=${moment()}`)
			const { platform } = await CapacitorDevice.getInfo()
			config.value.platform = platform
			try {
				store.commit('setLoadingState', { loadingState: true })
				error.value = null
				let user = null
				if (Capacitor.getPlatform() === `web`) {
					user = auth.currentUser
				} else {
					const result = await FirebaseAuthentication.getCurrentUser()
					user = result.user
				}
				if (user) {
					bindCatalog()
					bindMessage(user.uid)
					const result = await verifyCustomer('app')
					const draftSales = await customerApi.batchGetCustomerSales(user.uid, { shopId: 'q4eNQXGSdSpQyULtHVoZ', status: 'DRAFTED' })
					if (draftSales.length > 0) bindDoc('draftCart', 'sales', draftSales[0].id)
					store.commit('setLoadingState', { loadingState: false })
					if (result) return router.push('/home')
				}
				bindCatalog()
				store.commit('setLoadingState', { loadingState: false })
				return router.push('/launch')
			} catch (e) {
				console.error(e)
				error.value = e
				router.push('/launch')
				store.commit('setLoadingState', { loadingState: false })
				setTimeout(() => {
					error.value = null
				}, 10000)
			}
		})

		async function capturePayment() {
			try {
				store.commit('setLoadingState', { loadingState: true })
				error.value = null
				const saleId = currentCart.value.id
				await saleApi.paypalCapture(saleId, { paypalId: paypalData.value.id })
				getCurrentSale(saleId)
				resetCart()
				setCurrentCartMethod(currentShop.value.saleSetting.methods[1])
				store.commit('setLoadingState', { loadingState: false })
				router.push(`/account/orders/${saleId}`)
			} catch (e) {
				console.error(e)
				error.value = { message: `付款失敗，請重新付款` }
				store.commit('setLoadingState', { loadingState: false })
				setTimeout(() => {
					error.value = null
				}, 10000)
			}
		}

		watch(currentShop, newValue => {
			setCurrentCartMethod(newValue.saleSetting.methods[1])
		})

		return {
			config,
			error,
			isLoading,
			data,
			capturePayment,
		}
	},
}
</script>
