const { sendApi } = require('./base.api')

const createLeave = async (leaveRequest = {}) => await sendApi('post', `/leaves/create`, leaveRequest)
const batchGetLeaves = async (leaveRequest = {}) => await sendApi('post', `/leaves/batchGet`, leaveRequest)
const getLeave = async (id = ``) => await sendApi('post', `/leaves/${id}/get`)
const updateLeave = async (id = ``, leaveRequest = {}) => await sendApi('post', `/leaves/${id}/update`, leaveRequest)
const deleteLeave = async (id = ``) => await sendApi('post', `/leaves/${id}/delete`)

module.exports = {
	createLeave,
	batchGetLeaves,
	getLeave,
	updateLeave,
	deleteLeave,
}
