const { sendApi } = require('./base.api')

// 採購訂單
const draftPurchase = async (purchaseRequest = {}) => await sendApi('post', `/purchases/draft`, purchaseRequest)
const batchGetPurchases = async (purchaseRequest = {}) => await sendApi('post', `/purchases/batchGet`, purchaseRequest)
const getPurchase = async (id = ``) => await sendApi('post', `/purchases/${id}/get`)
const openPurchase = async (id = ``, purchaseRequest = {}) => await sendApi('post', `/purchases/${id}/open`, purchaseRequest)
const receivePurchase = async (id = ``, purchaseRequest = {}) => await sendApi('post', `/purchases/${id}/receive`, purchaseRequest)
const closePurchase = async (id = ``, purchaseRequest = {}) => await sendApi('post', `/purchases/${id}/close`, purchaseRequest)
const cancelPurchase = async (id = ``, purchaseRequest = {}) => await sendApi('post', `/purchases/${id}/cancel`, purchaseRequest)
const updatePurchaseVendor = async (id = ``, purchaseRequest = {}) => await sendApi('post', `/purchases/${id}/vendor/update`, purchaseRequest)
const deletePurchaseVendor = async (id = ``) => await sendApi('post', `/purchases/${id}/vendor/delete`)
const updatePurchaseRemark = async (id = ``, purchaseRequest = {}) => await sendApi('post', `/purchases/${id}/remark/update`, purchaseRequest)

// 採購訂單商品
const createPurchaseProduct = async (id = ``, purchaseProductRequest = {}) => await sendApi('post', `/purchases/${id}/products/create`, purchaseProductRequest)
const updatePurchaseProduct = async (id = ``, index = -1, purchaseProductRequest = {}) => await sendApi('post', `/purchases/${id}/products/${index}/update`, purchaseProductRequest)
const cancelPurchaseProduct = async (id = ``, index = -1) => await sendApi('post', `/purchases/${id}/products/${index}/cancel`)

module.exports = {
	draftPurchase,
	batchGetPurchases,
	getPurchase,
	openPurchase,
	receivePurchase,
	closePurchase,
	cancelPurchase,
	updatePurchaseVendor,
	deletePurchaseVendor,
	updatePurchaseRemark,
	createPurchaseProduct,
	updatePurchaseProduct,
	cancelPurchaseProduct,
}
