const { sendApi } = require('./base.api')

const createSurcharge = async (surchargeRequest = {}) => await sendApi('post', `/surcharges/create`, surchargeRequest)
const batchGetSurcharges = async (surchargeRequest = {}) => await sendApi('post', `/surcharges/batchGet`, surchargeRequest)
const getSurcharge = async (id = ``) => await sendApi('post', `/surcharges/${id}/get`)
const updateSurcharge = async (id = ``, surchargeRequest = {}) => await sendApi('post', `/surcharges/${id}/update`, surchargeRequest)
const deleteSurcharge = async (id = ``) => await sendApi('post', `/surcharges/${id}/delete`)

module.exports = {
	createSurcharge,
	batchGetSurcharges,
	getSurcharge,
	updateSurcharge,
	deleteSurcharge,
}
