<template>
	<div id="baseLayout" class="h-screen overflow-scroll bg-white" :class="classes">
		<div class="mt-10">
			<slot />
		</div>
	</div>
</template>

<script setup>
import { storeToRefs } from 'pinia'
import { useSharedStore } from '@/store/shared'
import { reactive, computed } from 'vue'

const { config } = storeToRefs(useSharedStore())

const classNameMap = reactive({
	platform: {
		ios: 'pt-safe pb-safe',
		web: 'py-4',
		android: 'py-4',
	},
})

const classes = computed(() => {
	const result = []
	if (config.value.platform) result.push(classNameMap.platform[config.value.platform])
	return result.join(' ')
})
</script>
