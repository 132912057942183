<template>
	<div class="w-full bg-white px-5 py-2">
		<div class="bg-light-grey p-2 flex rounded-xl">
			<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
				stroke="currentColor">
				<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
					d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
			</svg>
			<slot />
		</div>
	</div>
</template>
