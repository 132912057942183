import { createApp } from 'vue'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import { createI18n } from 'vue-i18n'
import zhHK from './data/TC' // 存放繁體系檔
import zhCN from './data/SC' // 存放繁體系檔
import enUS from './data/EN' // 存放英文語系檔

import Toolbar from '@/components/Toolbar'
import Icon from './components/Icon'
import Loading from '@/components/Loading'
import Alert from '@/components/Alert'
import Button from '@/components/Button'
import Footer from '@/components/Footer'
import List from '@/components/List'
import ListItem from '@/components/ListItem'
import Toast from '@/components/Toast'
import Modal from '@/components/Modal'
import Search from '@/components/Search'
import Popup from '@/components/Popup'

import App from './App.vue'
import router from './router'
import store from './store'
import { useSharedStore } from './store/shared'
import 'swiper/swiper.scss'
import 'swiper/components/pagination/pagination.scss'
import './index.css'
import VueQrcode from '@chenfengyuan/vue-qrcode'
import BaseLayout from '@/layouts/BaseLayout.vue'

import { onAuthStateChanged } from 'firebase/auth'
import { auth } from './firebase'
console.log('navigator', navigator.language)
// 預設使用的語系
let locale = 'EN'
// 檢查 localStorage 是否已有保存使用者選用的語系資訊
if (localStorage.getItem('footmark-lang')) {
	locale = localStorage.getItem('footmark-lang')
} else {
	// // 預設 EN
	// if (navigator.language != 'zh-HK' && navigator.language != 'zh-CN') {
	// 	locale = 'EN'
	// }
	// 檢查 繁 / 簡
	if (navigator.language == 'zh-HK') locale = 'TC'
	if (navigator.language == 'zh-CN') locale = 'SC'
}

const i18n = createI18n({
	legacy: false,
	locale: locale,
	messages: {
		TC: zhHK,
		SC: zhCN,
		EN: enUS,
	},
})
const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

let app = null
onAuthStateChanged(auth, () => {
	if (!app) {
		app = createApp(App).use(pinia).use(store).use(router).use(i18n)
		app.component('qrcode', VueQrcode)
		app.component('Icon', Icon)
		app.component('Button', Button)
		app.component('Toolbar', Toolbar)
		app.component('Alert', Alert)
		app.component('Loading', Loading)
		app.component('BaseLayout', BaseLayout)

		app.component('Toast', Toast)
		app.component('List', List)
		app.component('ListItem', ListItem)
		app.component('Modal', Modal)
		app.component('Search', Search)
		app.component('Popup', Popup)

		app.component('Footer', Footer)
		app.mount('#app')

		// 取得setLang 功能
		const { setLang } = useSharedStore()

		// 檢查 localStorage 是否已有保存使用者選用的語系資訊
		if (localStorage.getItem('footmark-lang')) {
			locale = localStorage.getItem('footmark-lang')
			setLang(locale)
		} else {
			setLang(locale)
		}
	}
})
