const { sendApi } = require('./base.api')

// 盤點訂單
const draftTransfer = async (transferRequest = {}) => await sendApi('post', `/transfers/draft`, transferRequest)
const batchGetTransfers = async (transferRequest = {}) => await sendApi('post', `/transfers/batchGet`, transferRequest)
const getTransfer = async (id = ``) => await sendApi('post', `/transfers/${id}/get`)
const openTransfer = async (id = ``, transferRequest = {}) => await sendApi('post', `/transfers/${id}/open`, transferRequest)
const packTransfer = async (id = ``, transferRequest = {}) => await sendApi('post', `/transfers/${id}/pack`, transferRequest)
const deliverTransfer = async (id = ``, transferRequest = {}) => await sendApi('post', `/transfers/${id}/deliver`, transferRequest)
const receiveTransfer = async (id = ``, transferRequest = {}) => await sendApi('post', `/transfers/${id}/receive`, transferRequest)
const closeTransfer = async (id = ``, transferRequest = {}) => await sendApi('post', `/transfers/${id}/close`, transferRequest)
const cancelTransfer = async (id = ``, transferRequest = {}) => await sendApi('post', `/transfers/${id}/cancel`, transferRequest)
const updateTransferRemark = async (id = ``, transferRequest = {}) => await sendApi('post', `/transfers/${id}/remark/update`, transferRequest)

// 盤點訂單商品
const createTransferProduct = async (id = ``, transferProductRequest = {}) => await sendApi('post', `/transfers/${id}/products/create`, transferProductRequest)
const updateTransferProduct = async (id = ``, index = -1, transferProductRequest = {}) => await sendApi('post', `/transfers/${id}/products/${index}/update`, transferProductRequest)
const cancelTransferProduct = async (id = ``, index = -1) => await sendApi('post', `/transfers/${id}/products/${index}/cancel`)

module.exports = {
	draftTransfer,
	batchGetTransfers,
	getTransfer,
	openTransfer,
	packTransfer,
	deliverTransfer,
	receiveTransfer,
	closeTransfer,
	cancelTransfer,
	updateTransferRemark,
	createTransferProduct,
	updateTransferProduct,
	cancelTransferProduct,
}
