const { sendApi } = require('./base.api')

const createBrand = async (brandRequest = {}) => await sendApi('post', `/brands/create`, brandRequest)
const batchGetBrands = async (brandRequest = {}) => await sendApi('post', `/brands/batchGet`, brandRequest)
const getBrand = async (id = ``) => await sendApi('post', `/brands/${id}/get`)
const updateBrand = async (id = ``, brandRequest = {}) => await sendApi('post', `/brands/${id}/update`, brandRequest)
const deleteBrand = async (id = ``) => await sendApi('post', `/brands/${id}/delete`)

module.exports = {
	createBrand,
	batchGetBrands,
	getBrand,
	updateBrand,
	deleteBrand,
}
