import { createRouter, createWebHistory } from 'vue-router'
import { auth } from '@/firebase'
import store from '../store'
import { useCustomerStore } from '@/store/customer'
import TabLayout from '@/layouts/TabLayout'

import { FirebaseAuthentication } from '@capacitor-firebase/authentication'
import { Capacitor } from '@capacitor/core'

const routes = [
	{
		path: '/tutorial',
		component: () => import('@/views/Tutorial'),
	},
	{
		path: '/launch',
		component: () => import('@/views/Launch'),
	},
	{
		path: '/register',
		component: () => import('@/views/Register'),
	},
	{
		path: '/signin',
		component: () => import('@/views/SignIn'),
	},
	{
		path: '/privacyPolicy',
		component: () => import('@/views/PrivacyPolicy'),
	},
	{
		path: '/termsAndConditions',
		component: () => import('@/views/TermsAndConditions'),
	},
	{
		path: '/forgot-password',
		component: () => import('@/views/ForgotPassword'),
	},
	{
		path: '/account/profile',
		component: () => import('@/views/Profile'),
		meta: { requiresAuth: true },
	},
	{
		path: '/account/orders',
		component: () => import('@/views/OrderList'),
		meta: { requiresAuth: true },
	},
	{
		path: '/account/orders/:id',
		component: () => import('@/views/OrderDetail'),
		meta: { requiresAuth: true },
	},
	{
		path: '/account/addresses',
		component: () => import('@/views/AddressList'),
		meta: { requiresAuth: true },
	},
	{
		path: '/account/addresses/:id',
		component: () => import('@/views/AddressDetail'),
		meta: { requiresAuth: true },
	},
	{
		path: '/account/wishlist',
		component: () => import('@/views/Wishlist'),
		meta: { requiresAuth: true },
	},
	{
		path: '/account/chat',
		component: () => import('@/views/Chat'),
		meta: { requiresAuth: true },
	},
	{
		path: '/account/help',
		component: () => import('@/views/Help'),
		meta: { requiresAuth: true },
	},
	{
		path: '/account/setting',
		component: () => import('@/views/Setting'),
		meta: { requiresAuth: true },
	},
	{
		path: '/account/invite',
		component: () => import('@/views/Invite'),
		meta: { requiresAuth: true },
	},
	{
		path: '/products/:id',
		component: () => import('@/views/ProductDetail'),
	},
	{
		path: '/discounts/:id',
		component: () => import('@/views/DiscountDetail'),
	},
	{
		path: '/cart/shipment',
		component: () => import('@/views/Shipment'),
	},
	{
		path: '',
		component: TabLayout,
		children: [
			{
				path: '/home',
				component: () => import('@/views/Home'),
			},
			{
				path: '/categories',
				component: () => import('@/views/CategoryList'),
			},
			{
				path: '/categories/:id',
				component: () => import('@/views/CategoryDetail'),
			},
			{
				path: '/search',
				component: () => import('@/views/Search'),
			},
			{
				path: '/discounts',
				component: () => import('@/views/DiscountList'),
				meta: { requiresAuth: true },
			},
			{
				path: '/cart',
				component: () => import('@/views/Cart'),
				meta: { requiresAuth: true },
			},
			{
				path: '/account',
				component: () => import('@/views/Account'),
				meta: { requiresAuth: true },
			},
		],
	},
	{
		path: '/:catchAll(.*)',
		redirect: '/launch',
	},
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
})

router.beforeEach(async (to, from, next) => {
	const { verifyCustomer } = useCustomerStore()
	if (!to.matched.some(record => record.meta.requiresAuth)) return next()
	try {
		store.commit('setLoadingState', { loadingState: true })
		let user = null
		if (Capacitor.getPlatform() === `web`) {
			user = auth.currentUser
		} else {
			const result = await FirebaseAuthentication.getCurrentUser()
			user = result.user
		}
		if (!user) next({ path: '/launch' })
		if (!(await verifyCustomer('router'))) next({ path: '/launch' })
		store.commit('setLoadingState', { loadingState: false })
		next()
	} catch (e) {
		console.error(e)
		store.commit('setLoadingState', { loadingState: false })
		next({ path: '/launch' })
	}
})

// router.afterEach(() => {
//     window.scrollTo(0, 1)
//     window.scrollTo(0, 0)
// })

export default router
