const { sendApi } = require('./base.api')

const createCustomer = async (customerRequest = {}) => await sendApi('post', `/customers/create`, customerRequest)
const batchGetCustomers = async (customerRequest = {}) => await sendApi('post', `/customers/batchGet`, customerRequest)
const getCustomer = async (id = ``) => await sendApi('post', `/customers/${id}/get`)
const updateCustomer = async (id = ``, customerRequest = {}) => await sendApi('post', `/customers/${id}/update`, customerRequest)
const deleteCustomer = async (id = ``) => await sendApi('post', `/customers/${id}/delete`)
const referCustomer = async (id = ``, customerRequest = {}) => await sendApi('post', `/customers/${id}/refer`, customerRequest)
const batchGetCustomerSales = async (id = ``, customerRequest = {}) => await sendApi('post', `/customers/${id}/sales`, customerRequest)

module.exports = {
	createCustomer,
	batchGetCustomers,
	getCustomer,
	updateCustomer,
	deleteCustomer,
	referCustomer,
	batchGetCustomerSales,
}
