const { sendApi } = require('./base.api')

const createTerminal = async (terminalRequest = {}) => await sendApi('post', `/terminals/create`, terminalRequest)
const batchGetTerminals = async (terminalRequest = {}) => await sendApi('post', `/terminals/batchGet`, terminalRequest)
const getTerminal = async (id = ``) => await sendApi('post', `/terminals/${id}/get`)
const updateTerminal = async (id = ``, terminalRequest = {}) => await sendApi('post', `/terminals/${id}/update`, terminalRequest)
const deleteTerminal = async (id = ``) => await sendApi('post', `/terminals/${id}/delete`)

module.exports = {
	createTerminal,
	batchGetTerminals,
	getTerminal,
	updateTerminal,
	deleteTerminal,
}
