export function Chat() {
    return {
        id: null,
        lastMessage: null,
    }
}

export function Message() {
    return {
        id: null,
        type: 'TEXT',
        user: 'CUSTOMER',
        content: '',
        createdAt: Date.now(),
    }
}