const { sendApi } = require('./base.api')

const createMenu = async (menuRequest = {}) => await sendApi('post', `/menus/create`, menuRequest)
const batchGetMenus = async (menuRequest = {}) => await sendApi('post', `/menus/batchGet`, menuRequest)
const getMenu = async (id = ``) => await sendApi('post', `/menus/${id}/get`)
const updateMenu = async (id = ``, menuRequest = {}) => await sendApi('post', `/menus/${id}/update`, menuRequest)
const deleteMenu = async (id = ``) => await sendApi('post', `/menus/${id}/delete`)

module.exports = {
	createMenu,
	batchGetMenus,
	getMenu,
	updateMenu,
	deleteMenu,
}
