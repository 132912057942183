import { defineStore } from 'pinia'
import { Chat, Message } from '@/models/chat'
import { collection, setDoc, doc, runTransaction, query } from 'firebase/firestore'
import { db } from '@/firebase'
import { useSharedStore } from './shared'
// import { useCustomerStore } from './customer'

export const useChatStore = defineStore('chatStore', () => {
	const { bindCollection } = useSharedStore()

	async function createChat(customerId) {
		const chat = { ...Chat(), id: customerId }
		await setDoc(doc(db, 'chats', chat.id), chat)
	}
	async function createMessage(customerId, content) {
		const message = { ...Message(), id: doc(collection(db, 'chats', customerId, 'messages')).id, content: content }
		await setDoc(doc(db, 'chats', customerId, 'messages', message.id), message)
		await runTransaction(db, async transaction => {
			console.log('findChats')
			const chatSnap = await transaction.get(doc(db, 'chats', customerId))
			console.log('findChats', customerId, chatSnap)
			const chat = chatSnap.data()
			if (!chat) await createChat(customerId)
			console.log(chat)
			if (chat.lastMessage && chat.lastMessage.createdAt > message.createdAt) return
			transaction.update(doc(db, 'chats', customerId), { lastMessage: message })
		})
	}

	function bindMessage(customerId) {
		bindCollection({ key: 'messages', ref: query(collection(db, 'chats', customerId, 'messages')) })
	}

	// async function createMessage(id, text) {
	// 	const message = { ...Message(), id: db.collection('chats').doc(id).collection('messages').doc().id, content: text }
	// 	await db.collection('chats').doc(id).collection('messages').doc(message.id).set(message)
	// 	await db.runTransaction(async (transaction) => {
	// 		const chatSnap = await transaction.get(db.collection('chats').doc(id))
	// 		const chat = chatSnap.data()
	// 		if (chat.lastMessage && chat.lastMessage.createdAt > message.createdAt) return
	// 		transaction.update(db.collection('chats').doc(id), { lastMessage: message })
	// 	})
	// }

	// function bindCollection({ key, ref }) {
	// 	unsubscribes.value[key] = onSnapshot(ref, (querySnap) => {
	// 		bind.value[key] = []
	// 		querySnap.forEach((doc) => bind.value[key].push(doc.data()))
	// 	})
	// }

	// const messages = computed(() =>
	// 	bind.value.messages?.sort(function (oldValue, newValue) {
	// 		return oldValue.createdAt - newValue.createdAt
	// 	})
	// )

	return {
		bindMessage,
		createChat,
		createMessage,
		bindCollection,
		// messages,
	}
})
