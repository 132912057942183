const { sendApi } = require('./base.api')

const createExpense = async (expenseRequest = {}) => await sendApi('post', `/expenses/create`, expenseRequest)
const batchGetExpenses = async (expenseRequest = {}) => await sendApi('post', `/expenses/batchGet`, expenseRequest)
const getExpense = async (id = ``) => await sendApi('post', `/expenses/${id}/get`)
const updateExpense = async (id = ``, expenseRequest = {}) => await sendApi('post', `/expenses/${id}/update`, expenseRequest)
const deleteExpense = async (id = ``) => await sendApi('post', `/expenses/${id}/delete`)

module.exports = {
	createExpense,
	batchGetExpenses,
	getExpense,
	updateExpense,
	deleteExpense,
}
