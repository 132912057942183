const { sendApi } = require('./base.api')

const createPunch = async (punchRequest = {}) => await sendApi('post', `/punches/create`, punchRequest)
const batchGetPunches = async (punchRequest = {}) => await sendApi('post', `/punches/batchGet`, punchRequest)
const getPunch = async (id = ``) => await sendApi('post', `/punches/${id}/get`)
const updatePunch = async (id = ``, punchRequest = {}) => await sendApi('post', `/punches/${id}/update`, punchRequest)
const deletePunch = async (id = ``) => await sendApi('post', `/punches/${id}/delete`)

module.exports = {
	createPunch,
	batchGetPunches,
	getPunch,
	updatePunch,
	deletePunch,
}
