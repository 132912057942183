<template>
	<BaseLayout>
		<router-view />
	</BaseLayout>

	<Footer>
		<div class="grid grid-cols-4 bg-white">
			<div
				v-for="link in links"
				:key="link.value"
				class="relative col-span-1 mt-2 flex w-full transform flex-col items-center gap-1 whitespace-nowrap pt-2 text-xs motion-reduce:transform-none"
				@click="selectLink(link)"
				:class="$route.fullPath.includes(link.value) ? 'scale-105 font-bold text-primary' : 'text-muted'"
			>
				<div class="relative">
					<Icon class="h-7 w-7" :icon="link.icon" />
					<span class="absolute -right-2 -top-1 inline-flex h-[1.5em] w-[1.5em] items-center justify-center whitespace-nowrap rounded-full bg-primary text-white" v-if="link.value === '/cart' && cartProductQuantity > 0">{{ cartProductQuantity > 99 ? '99+' : cartProductQuantity }}</span>
				</div>
				<div>{{ link.text }}</div>
			</div>
		</div>
	</Footer>
</template>

<script>
import { storeToRefs } from 'pinia'
import { ref, computed, nextTick } from 'vue'
// import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { useCartStore } from '@/store/cart'
import { useSharedStore } from '@/store/shared'

export default {
	setup() {
		// const store = useStore()
		const router = useRouter()
		const { config } = storeToRefs(useSharedStore())
		const { currentProduct, currentCart } = storeToRefs(useCartStore())

		const { t } = useI18n({ useScope: 'global' })
		const links = ref([
			{ value: '/home', text: t('navBar.Myjetmall'), icon: 'myjetmall' },
			// { value: '/home?menuType=LIFESTYLE', text: t('navBar.lifestyle'), icon: 'lifestyle' },
			{ value: '/discounts', text: t('navBar.vouchers'), icon: 'gift' },
			{ value: '/cart', text: t('navBar.cart'), icon: 'cart' },
			{ value: '/account', text: t('navBar.myAccount'), icon: 'user' },
		])
		const cartProductQuantity = computed(() => {
			if (currentCart.value.id == null) return 0
			const cartProducts = currentCart.value.products.filter(product => product.status === 'CLOSED')
			return cartProducts.reduce((sum, p) => sum + p.orderedQuantity, 0)
		})

		function selectLink(link) {
			const element = document.getElementById('baseLayout')
			nextTick(() => element.scrollIntoView({ behavior: 'auto', block: 'start' }))
			currentProduct.value = null
			router.push(link.value)
		}

		return {
			links,
			config,
			cartProductQuantity,
			selectLink,
		}
	},
}
</script>
